var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "box-sizing": "border-box", padding: "15px" } },
    [
      _c(
        "div",
        { staticClass: "nav" },
        _vm._l(_vm.navList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              class: ["nav_item", _vm.navActive == item.id ? "active" : ""],
              on: {
                click: function ($event) {
                  return _vm.changeNav(item)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _vm.navActive == 1
        ? _c(
            "el-col",
            { staticClass: "order_bg", attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, model: _vm.queryData },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "签单时间", prop: "payTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          size: "mini",
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          clearable: "",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: { clear: _vm.payTimehandleClear },
                        model: {
                          value: _vm.queryData.payTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "payTime", $$v)
                          },
                          expression: "queryData.payTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号", prop: "orderCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入订单编号",
                          clearable: "",
                        },
                        model: {
                          value: _vm.queryData.orderCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "orderCode", $$v)
                          },
                          expression: "queryData.orderCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品分类", prop: "classifyNameTwo" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择产品分类",
                            clearable: "",
                            size: "mini",
                          },
                          model: {
                            value: _vm.queryData.classifyNameTwo,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "classifyNameTwo", $$v)
                            },
                            expression: "queryData.classifyNameTwo",
                          },
                        },
                        _vm._l(_vm.productCategories, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称", prop: "productName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入产品名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.queryData.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "productName", $$v)
                          },
                          expression: "queryData.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户来源", prop: "customerSource" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择客户来源",
                          },
                          model: {
                            value: _vm.queryData.customerSource,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "customerSource", $$v)
                            },
                            expression: "queryData.customerSource",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "网络客户", value: "网络客户" },
                          }),
                          _c("el-option", {
                            attrs: { label: "转介绍", value: "转介绍" },
                          }),
                          _c("el-option", {
                            attrs: { label: "老客户", value: "老客户" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型", prop: "orderType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择订单类型",
                          },
                          model: {
                            value: _vm.queryData.orderType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "orderType", $$v)
                            },
                            expression: "queryData.orderType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "续费订单", value: "续费订单" },
                          }),
                          _c("el-option", {
                            attrs: { label: "增值订单", value: "增值订单" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "部门名称", prop: "departmentName" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择部门",
                          },
                          model: {
                            value: _vm.queryData.departmentName,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "departmentName", $$v)
                            },
                            expression: "queryData.departmentName",
                          },
                        },
                        _vm._l(_vm.allDepartment, function (item) {
                          return _c("el-option", {
                            key: item.departmentId,
                            attrs: {
                              label: item.departmentName,
                              value: item.departmentName,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "业绩归属人", prop: "pushCustomerName" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入业绩归属人",
                          clearable: "",
                        },
                        model: {
                          value: _vm.queryData.pushCustomerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "pushCustomerName", $$v)
                          },
                          expression: "queryData.pushCustomerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否合作单", prop: "isCooperation" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择是否有效单",
                          },
                          model: {
                            value: _vm.queryData.isCooperation,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "isCooperation", $$v)
                            },
                            expression: "queryData.isCooperation",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "是", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "否", value: "0" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.search },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", plain: "", type: "primary" },
                          on: { click: _vm.resetForm },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.tableData &&
              _vm.tableData.length > 0 &&
              (_vm.totalData.num || _vm.totalData.price)
                ? _c(
                    "div",
                    { staticClass: "total_data" },
                    [
                      _vm._l(_vm.orderData, function (item, index) {
                        return _c(
                          "el-tag",
                          { key: index, attrs: { type: "success" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.orderType) +
                                ":" +
                                _vm._s(item.orderCount) +
                                " "
                            ),
                          ]
                        )
                      }),
                      _vm._l(_vm.statisticsData, function (item, index) {
                        return _c("el-tag", { key: index }, [
                          _vm._v(
                            " " +
                              _vm._s(item.customerSource) +
                              ":" +
                              _vm._s(item.orderCount) +
                              " "
                          ),
                        ])
                      }),
                      _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v(" 产品数量：" + _vm._s(_vm.totalData.num)),
                      ]),
                      _c("el-tag", { attrs: { type: "danger" } }, [
                        _vm._v(
                          "合计金额：" +
                            _vm._s(
                              _vm._f("numberWithCommas")(
                                Number(_vm.totalData.price).toFixed(2)
                              )
                            ) +
                            "元"
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-table",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.tableData,
                    stripe: "",
                    border: "",
                    height: "530",
                    "header-cell-style": {
                      background: "#f2f3f5",
                      color: "#7a7e83",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      width: "50",
                      label: "序号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "orderCode",
                      label: "订单号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "150",
                      prop: "customerNo",
                      label: "客户编码",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "150",
                      prop: "departmentName",
                      label: "部门名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "pushCustomerName",
                      label: "业绩归属人",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "payTime",
                      label: "签单时间",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "payType",
                      label: "支付方式",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "productId",
                      label: "产品id",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "330",
                      prop: "skuName",
                      label: "产品名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "classifyNameTwo",
                      label: "产品分类",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "skuNum",
                      label: "产品数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "130",
                      prop: "price",
                      label: "实付总价",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "isCooperation",
                      label: "是否合作单",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.isCooperation == 1 ? "是" : "否"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2410835459
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "cooperationType",
                      label: "合作模式",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.isCooperation,
                                      expression: " scope.row.isCooperation",
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.cooperationType == 1
                                          ? "比例"
                                          : "产品"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3870591682
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "masterName",
                      label: "公司名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "customerSource",
                      label: "客户来源",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "orderType",
                      label: "订单类型",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "serviceCycle",
                      label: "服务周期",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "refundTime",
                      label: "退款时间",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "isAcrossRefund",
                      label: "是否当月退款",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.isAcrossRefund == 1 ? "是" : "否"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      699579883
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "remark",
                      label: "备注",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      "min-width": "150",
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    icon: "el-icon-edit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.additionalId
                                        ? "修改内容"
                                        : "补充内容"
                                    ) + " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3917938585
                    ),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryData.pageNum,
                  limit: _vm.queryData.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryData, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryData, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.navActive == 2
        ? _c(
            "el-col",
            { staticClass: "order_bg", attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "refunForm",
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, model: _vm.refundData },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "业绩归属人", prop: "pushCustomerName" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入业绩归属人",
                          clearable: "",
                        },
                        model: {
                          value: _vm.refundData.pushCustomerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundData, "pushCustomerName", $$v)
                          },
                          expression: "refundData.pushCustomerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号", prop: "orderCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入订单编号",
                          clearable: "",
                        },
                        model: {
                          value: _vm.refundData.orderCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundData, "orderCode", $$v)
                          },
                          expression: "refundData.orderCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "部门名称", prop: "departmentName" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择部门",
                          },
                          model: {
                            value: _vm.refundData.departmentName,
                            callback: function ($$v) {
                              _vm.$set(_vm.refundData, "departmentName", $$v)
                            },
                            expression: "refundData.departmentName",
                          },
                        },
                        _vm._l(_vm.allDepartment, function (item) {
                          return _c("el-option", {
                            key: item.departmentId,
                            attrs: {
                              label: item.departmentName,
                              value: item.departmentName,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品分类", prop: "classifyNameTwo" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择产品分类",
                            clearable: "",
                            size: "mini",
                          },
                          model: {
                            value: _vm.refundData.classifyNameTwo,
                            callback: function ($$v) {
                              _vm.$set(_vm.refundData, "classifyNameTwo", $$v)
                            },
                            expression: "refundData.classifyNameTwo",
                          },
                        },
                        _vm._l(_vm.productCategories, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称", prop: "productName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入产品名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.refundData.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundData, "productName", $$v)
                          },
                          expression: "refundData.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款原因", prop: "refundcase" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入退款原因",
                          clearable: "",
                        },
                        model: {
                          value: _vm.refundData.refundCase,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundData, "refundCase", $$v)
                          },
                          expression: "refundData.refundCase",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款类型", prop: "refundType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择退款类型",
                          },
                          model: {
                            value: _vm.refundData.refundType,
                            callback: function ($$v) {
                              _vm.$set(_vm.refundData, "refundType", $$v)
                            },
                            expression: "refundData.refundType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "客户预存款", value: "客户预存款" },
                          }),
                          _c("el-option", {
                            attrs: { label: "退款", value: "退款" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款状态", prop: "refundStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择退款状态",
                          },
                          model: {
                            value: _vm.refundData.refundStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.refundData, "refundStatus", $$v)
                            },
                            expression: "refundData.refundStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "已申请", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "退款中", value: "2" },
                          }),
                          _c("el-option", {
                            attrs: { label: "退款成功", value: "3" },
                          }),
                          _c("el-option", {
                            attrs: { label: "退款失败", value: "4" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "是否当月退款", prop: "isAcrossRefund" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择是否当月退款",
                          },
                          model: {
                            value: _vm.refundData.isAcrossRefund,
                            callback: function ($$v) {
                              _vm.$set(_vm.refundData, "isAcrossRefund", $$v)
                            },
                            expression: "refundData.isAcrossRefund",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "是", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "否", value: "0" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退单时间", prop: "payTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          size: "mini",
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          clearable: "",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: { clear: _vm.refundTimehandleClear },
                        model: {
                          value: _vm.refundData.refundTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.refundData, "refundTime", $$v)
                          },
                          expression: "refundData.refundTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否合作单", prop: "isCooperation" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请选择是否有效单",
                          },
                          model: {
                            value: _vm.refundData.isCooperation,
                            callback: function ($$v) {
                              _vm.$set(_vm.refundData, "isCooperation", $$v)
                            },
                            expression: "refundData.isCooperation",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "是", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "否", value: "0" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.refundSearch },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", plain: "", type: "primary" },
                          on: { click: _vm.refundResetForm },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "total_data" },
                [
                  _c("el-tag", { attrs: { type: "success" } }, [
                    _vm._v(
                      " 退款金额:" +
                        _vm._s(_vm.refundDetail.actuallyRefundPrice) +
                        " "
                    ),
                  ]),
                  _c("el-tag", { attrs: { type: "success" } }, [
                    _vm._v(
                      " 退款商品数量:" +
                        _vm._s(_vm.refundDetail.refundNum) +
                        " "
                    ),
                  ]),
                  _vm.cooperateRefund
                    ? _c("el-tag", { attrs: { type: "primary" } }, [
                        _vm._v(
                          " 合作单退款金额:" + _vm._s(_vm.cooperateRefund) + " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  ref: "refunTable",
                  attrs: {
                    data: _vm.refunTable,
                    stripe: "",
                    border: "",
                    height: "580",
                    "header-cell-style": {
                      background: "#f2f3f5",
                      color: "#7a7e83",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      width: "50",
                      label: "序号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "orderCode",
                      label: "订单号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "150",
                      prop: "customerNo",
                      label: "客户编码",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "pushCustomerName",
                      label: "业绩归属人",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "departmentName",
                      label: "部门名称",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "classifyNameTwo",
                      label: "产品分类",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "productId",
                      label: "产品id",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "330",
                      prop: "skuName",
                      label: "产品名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "skuNum",
                      label: "购买数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "refundNum",
                      label: "退款数量",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "150",
                      prop: "refundPrice",
                      label: "退款金额",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "150",
                      prop: "actuallyRefundPrice",
                      label: "退款实际金额",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "sheetId",
                      label: "退款流程号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "refundCase",
                      label: "退单原因",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "refundStatus",
                      label: "退款状态",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "refundType",
                      label: "退款类型",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(" " + _vm._s(scope.row.refundType) + " "),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3507326827
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "160",
                      prop: "applyTime",
                      label: "发起日期",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "160",
                      prop: "refundTime",
                      label: "退款时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "120",
                      prop: "isAcrossRefund",
                      label: "是否当月退款",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.isAcrossRefund == 1 ? "是" : "否"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      699579883
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "isCooperation",
                      label: "是否合作单",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.isCooperation == 1 ? "是" : "否"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2410835459
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "100",
                      prop: "cooperationType",
                      label: "合作模式",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.isCooperation,
                                      expression: " scope.row.isCooperation",
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.cooperationType == 1
                                          ? "比例"
                                          : "产品"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3870591682
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "min-width": "200",
                      prop: "remark",
                      label: "备注",
                    },
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.refundTotal,
                  page: _vm.refundData.pageNum,
                  limit: _vm.refundData.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.refundData, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.refundData, "pageSize", $event)
                  },
                  pagination: _vm.getRefunTable,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.newData.id ? "修改内容" : "补充内容",
            visible: _vm.dialogVisible,
            center: true,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.newData, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户来源", prop: "customerSource" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择客户来源" },
                      model: {
                        value: _vm.newData.customerSource,
                        callback: function ($$v) {
                          _vm.$set(_vm.newData, "customerSource", $$v)
                        },
                        expression: "newData.customerSource",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "网络客户", value: "网络客户" },
                      }),
                      _c("el-option", {
                        attrs: { label: "转介绍", value: "转介绍" },
                      }),
                      _c("el-option", {
                        attrs: { label: "老客户", value: "老客户" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单类型", prop: "orderType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择订单类型" },
                      model: {
                        value: _vm.newData.orderType,
                        callback: function ($$v) {
                          _vm.$set(_vm.newData, "orderType", $$v)
                        },
                        expression: "newData.orderType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "续费订单", value: "续费订单" },
                      }),
                      _c("el-option", {
                        attrs: { label: "增值订单", value: "增值订单" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "masterName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newData.masterName,
                      callback: function ($$v) {
                        _vm.$set(_vm.newData, "masterName", $$v)
                      },
                      expression: "newData.masterName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "服务周期", prop: "serviceCycle" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      clearable: "",
                      format: "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.newData.serviceCycle,
                      callback: function ($$v) {
                        _vm.$set(_vm.newData, "serviceCycle", $$v)
                      },
                      expression: "newData.serviceCycle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.newData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.newData, "remark", $$v)
                      },
                      expression: "newData.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveOrUpdate } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }