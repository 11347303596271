<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" title="产品分析数据" width="80%" @close="handleClose" center>
      <transition name="el-fade-in-linear">
        <div class="table_box">
          <el-table  v-loading="loading" :data="productData" border>
            <el-table-column prop="productName" label="产品分类" align="center"></el-table-column>
            <el-table-column prop="orderCount" label="订单量" align="center"></el-table-column>
            <el-table-column prop="orderPriceSum" label="销售额" align="center"></el-table-column>
            <el-table-column v-for="(dept, index) in departments" :key="index" :label="!departmentId?dept.departmentName:dept.salesmanName" align="center">
              <template slot-scope="{ row }">
                <div v-if="!departmentId"> {{showPrice(dept.departmentName,row.achievementUserSourceVoList)}}</div>
                <div v-else> {{showPrice(dept.salesmanName,row.achievementUserSourceVoList)}}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </transition>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { findSalesmanAll, findDepartmentAll } from "@/api/analysis/data";

export default {
  props: {
    departmentId: {
      type: Number,
      default: null,
    },
    productData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: true,
      departments: [], // 初始为空，后续根据条件填充
    };
  },
  mounted() {},
  watch: {
    departmentId: {
      immediate: true, // 立即触发，防止初始未加载
      handler(newVal) {
        if (!newVal) {
          // departmentId 为空时，使用所有部门数据
          this.getfindDepartmentAll();
        } else {
          // departmentId 不为空时，请求数据
          this.findSalesmanHandle();
        }
      },
    },
  },
  methods: {
    showPrice(name, achievementUserSourceVoList) {
      let findItem;
      if (!this.departmentId) {
        findItem = achievementUserSourceVoList.filter(
          (item) => item.departmentName === name
        );
      } else {
        findItem = achievementUserSourceVoList.filter(
          (item) => item.salesmanName === name
        );
      }

      if (findItem.length > 0) {
        return findItem[0].orderPriceSum;
      } else {
        return 0;
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
    },
    closeLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    findSalesmanHandle() {
      this.loading = true;
      findSalesmanAll({ departmentId: this.departmentId }).then((res) => {
        if (res.code === 200) {
          this.departments = res.data;
          this.closeLoading();
        } else {
          this.$message.error("获取数据失败");
          this.closeLoading();
        }
      });
    },
    async getfindDepartmentAll() {
      this.loading = true;
      let res = await findDepartmentAll();
      if (res.code == 200) {
        this.departments = res.data.filter(
          (item) =>
            item.departmentName &&
            !item.departmentName.includes("专业产品部") &&
            !item.departmentName.includes("互联网产品组")
        );
        this.closeLoading();
      }
    },
  },
};
</script>
<style scoped>
.table_box {
  height: 500px;
  overflow: auto;
}
</style>