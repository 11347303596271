var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card2",
          attrs: {
            shadow: "hover",
            "body-style": { padding: "10px" },
            header: "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "card_head",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("img", {
                    staticClass: "title-icon",
                    attrs: {
                      src: "https://oss.71go.com/qyg-pc/oa/titleIcon.png",
                      alt: "",
                    },
                  }),
                  _c(
                    "el-tabs",
                    {
                      staticStyle: { width: "100%" },
                      on: { "tab-click": _vm.dataDetailClick },
                      model: {
                        value: _vm.dataDetail,
                        callback: function ($$v) {
                          _vm.dataDetail = $$v
                        },
                        expression: "dataDetail",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "产品数据明细", name: "0" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "订单数据明细", name: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dataDetail == 0
                ? _c(
                    "div",
                    { staticClass: "searchbox" },
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { type: "card" },
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.sortField,
                            callback: function ($$v) {
                              _vm.sortField = $$v
                            },
                            expression: "sortField",
                          },
                        },
                        [
                          _c("el-tab-pane", {
                            attrs: { label: "成交总额", name: "0" },
                          }),
                          _c("el-tab-pane", {
                            attrs: { label: "成交总量", name: "1" },
                          }),
                          _c("el-tab-pane", {
                            attrs: { label: "产品单价", name: "2" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "inputbox" },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: {
                              placeholder: "请输入产品名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.productName,
                              callback: function ($$v) {
                                _vm.productName = $$v
                              },
                              expression: "productName",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticClass: "search",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleClick($event)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "searchbox" },
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { type: "card" },
                          on: { "tab-click": _vm.dataDetailClick },
                          model: {
                            value: _vm.sortField,
                            callback: function ($$v) {
                              _vm.sortField = $$v
                            },
                            expression: "sortField",
                          },
                        },
                        [
                          _c("el-tab-pane", {
                            attrs: { label: "订单总额", name: "0" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "inputbox" },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            attrs: {
                              placeholder: "请输入产品名称",
                              clearable: "",
                            },
                            model: {
                              value: _vm.productName,
                              callback: function ($$v) {
                                _vm.productName = $$v
                              },
                              expression: "productName",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticClass: "search",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.dataDetailClick($event)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]
          ),
          _vm.dataDetail == 0
            ? _c(
                "el-table",
                {
                  staticClass: "table-box",
                  staticStyle: { width: "100%", "overflow-x": "hidden" },
                  attrs: {
                    "header-cell-style": _vm.hearderStyle,
                    data: _vm.tableData,
                    stripe: "",
                    height: "1330px",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "100px", label: "排名" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(" " + _vm._s(scope.$index + 1) + " "),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      192879353
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "200px",
                      "show-overflow-tooltip": "",
                      prop: "productName",
                      label: "产品名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "actualPriceSum", label: "成交额" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "productCount", label: "成交量" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "averagePrice", label: "平均售价" },
                  }),
                ],
                1
              )
            : _c(
                "el-table",
                {
                  staticClass: "table-box",
                  staticStyle: { width: "100%", "overflow-x": "hidden" },
                  attrs: {
                    "header-cell-style": _vm.hearderStyle,
                    data: _vm.tableData,
                    stripe: "",
                    height: "1330px",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "100px", label: "排名" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "180px",
                      prop: "orderCode",
                      label: "订单编号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { width: "100px", prop: "price", label: "订单总额" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": "",
                      prop: "childSkuName",
                      label: "产品",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }