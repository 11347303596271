import request from '@/utils/request_oa'

export function findAchievementInfoBySalesmanId(data) {
  return request({
    url: '/sm/simeng/api/achievementOrder/findAchievementInfoBySalesmanId',
    method: 'get',
    params:data
  })
}

export function findChartBySalesmanId(data) {
    return request({
      url: '/sm/simeng/api/achievementOrder/findChartBySalesmanId',
      method: 'get',
      params:data
    })
  }



  export function findYearChartByDepartmentId(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findYearChartByDepartmentId',
      method: 'get',
      params:data
    })
  }
  export function findAchievementInfoByDepartmentId(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findAchievementInfoByDepartmentId',
      method: 'get',
      params:data
    })
  }
  //集团战区
  export function findAchievementInfoByDepartment(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findAchievementInfoByDepartment',
      method: 'get',
      params:data
    })
  }
  // 战区年度看板
  export function findYearChartByZoneId(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findYearChartByZoneId',
      method: 'get',
      params:data
    })
  }
  export function findYearChart(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findYearChart',
      method: 'get',
      params:data
    })
  }

  // 查询所有部门
  export function findDepartmentAll(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findDepartmentAll',
      method: 'get',
      params:data
    })
  }


  export function findZoneAll(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findZoneAll',
      method: 'get',
      params:data
    })
  }

  export function findAchievementInfoByZone(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findAchievementInfoByZone',
      method: 'get',
      params:data
    })
  }

  export function findSalesmanAll(data) {
    return request({
      url: 'sm//simeng/api/achievementOrder/findSalesmanAll',
      method: 'get',
      params:data
    })
  }
  export function findSaleInfo(data) {
    return request({
      url: 'sm/simeng/api/achievementProduct/findSaleInfo',
      method: 'get',
      params:data
    })
  }
  export function findAchievementInfoSummary(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findAchievementInfoSummary',
      method: 'get',
      params:data
    })
  }

  export function findSummaryAchievement(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findSummaryAchievement',
      method: 'get',
      params:data
    })
  }
  export function findWeekRangeList(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findWeekRangeList',
      method: 'get',
      params:data
    })
  }

  export function findTargetInfo(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findTargetInfo',
      method: 'get',
      params:data
    })
  }


  export function pageQuery(data) {
    return request({
      url: 'sm/simeng/api/additional/pageQuery',
      method: 'get',
      params:data
    })
  }
  export function customerSourceStatistics(data) {
    return request({
      url: 'sm/simeng/api/additional/customerSourceStatistics',
      method: 'get',
      params:data
    })
  }
  export function orderTypeStatistics(data) {
    return request({
      url: 'sm/simeng/api/additional/orderTypeStatistics',
      method: 'get',
      params:data
    })
  }

  export function orderProductStatistics(data) {
    return request({
      url: 'sm/simeng/api/additional/orderProductStatistics',
      method: 'get',
      params:data
    })
  }

  export function refundOrderProductStatistics(data) {
    return request({
      url: 'sm/simeng/api/additional/refundOrderProductStatistics',
      method: 'get',
      params:data
    })
  }

  export function findCooperateRefund(data) {
    return request({
      url: 'sm/simeng/api/additional/findCooperateRefund',
      method: 'get',
      params:data
    })
  }

  export function createOrder(data) {
    return request({
      url: 'sm/simeng/api/additional/create',
      method: 'post',
      data:data
    })
  }
  export function updateOrder(data) {
    return request({
      url: 'sm/simeng/api/additional/updateById',
      method: 'post',
      data:data
    })
  }

  export function pageQueryRefund(data) {
    return request({
      url: 'sm/simeng/api/additional/pageQueryRefund',
      method: 'get',
      params:data
    })
  }

  export function findRankingList(data) {
    return request({
      url: 'sm/simeng/api/achievementOrder/findRankingList',
      method: 'get',
      params:data
    })
  }
  export function addRecord(data) {
    return request({
      url: 'sm/simeng/api/clickRecord/addRecord',
      method: 'post',
      data:data
    })
  }

  // 查询个人/部门近期（今日，周）业绩情况
  export function findRecentInfo(data) {
    return request({
      url: 'sm/simeng/api/achievementProduct/findRecentInfo',
      method: 'get',
      params:data
    })
  }

  // 查询个人/部门月业绩情况

  export function findMonthInfo(data) {
    return request({
      url: 'sm/simeng/api/achievementProduct/findMonthInfo',
      method: 'get',
      params:data
    })
  }

//查询部门业务员销售信息
export function findSalesmanInfoByDepartment(data) {
  return request({
    url: 'sm/simeng/api/achievementProduct/findSalesmanInfoByDepartment',
    method: 'get',
    params:data
  })
}
// 查询个人/部门年业绩情况
export function findYearInfo(data) {
  return request({
    url: 'sm/simeng/api/achievementProduct/findYearInfo',
    method: 'get',
    params:data
  })
}

// 查询个人/部门产品销售信息列表
export function findProductSaleInfo(data) {
  return request({
    url: 'sm/simeng/api/achievementProduct/findProductSaleInfo',
    method: 'get',
    params:data
  })
}

// 查询个人/部门产品分类销售信息列表
export function findSalesmanInfoByClass(data) {
  return request({
    url: 'sm/simeng/api/achievementProduct/findSalesmanInfoByClass',
    method: 'get',
    params:data
  })
}
// 查询业务员负责领域
export function findClassBySalesmanId(data) {
  return request({
    url: 'sm/simeng/api/achievementProduct/findClassBySalesmanId',
    method: 'get',
    params:data
  })
}
// 查询个人/部门业绩图表
export function findSaleInfoChart(data) {
  return request({
    url: 'sm/simeng/api/achievementProduct/findSaleInfoChart',
    method: 'get',
    params:data
  })
}

