<template>
  <div class="table">
    <el-card
      shadow="hover"
      :body-style="{ padding: '10px' }"
      class="box-card2"
      header=""
    >
      <el-table :header-cell-style="hearderStyle" :data="tableData" stripe class="table-box" style="width: 100%;overflow-x:hidden;" max-height="260px"  :summary-method="getSummaries" show-summary>
        <el-table-column prop="salesmanName" label="专员" >
        </el-table-column>
        <el-table-column prop="target" label="目标" >
        </el-table-column>
        <el-table-column prop="actualPriceSum" label="业绩额" >
        </el-table-column>
        <el-table-column  label="完成率">
          <template slot-scope="scope">
            {{getTagetNum(scope.row.actualPriceSum,scope.row.target) }}%
          </template>
        </el-table-column>
        <el-table-column  label="排名">
          <template slot-scope="scope">
            {{ scope.$index+1 }}
          </template> </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
    },
    departmentId: {
      type: Number, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
    },
    classifyNameTwo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      input: "",
    };
  },
  mounted() {

  },
  methods: {  
    hearderStyle(row, rowIndex){
        return 'background: #EFF4FF;color:#1C1F23;text-align:left;font-family: PingFang SC, PingFang SC;font-weight: bold;font-size: 14px;'
    },
    // 完成率计算
    getTagetNum(num, tagetNum) {
      let taget = 0;
      if (tagetNum !== null && tagetNum > 0) {
        taget = (num / tagetNum) * 100;
      }
      return taget.toFixed(2);
    },
    getSummaries(param) {
        const { columns, data } = param;
        console.log('columns',columns);
        console.log('data',data);
        const sums = [];
        // 映射列索引到数据属性
        const columnMapping = {
            1: 'target',
            2: 'actualPriceSum',
            3: 'percentage'
        };
        let totalTarget = 0;
        let totalActualPriceSum = 0;
            
        // 计算所有数据的合计：target 和 actualPriceSum 的总和
        data.forEach(item => {
          totalTarget += item.target || 0; // 确保字段为空时默认加 0
          totalActualPriceSum += item.actualPriceSum || 0; // 确保字段为空时默认加 0
        });
        columns.forEach((column, index) => {
          console.log('column.property',column.property);
          if (index === 0) {
            sums[index] = '合计';
            return;
          }
          if (data && data.length > 0) {
            const fieldName = columnMapping[index];
            if (fieldName) {
                if (fieldName === 'percentage') {
                  // 合计行的百分比计算：总的 actualPriceSum / 总的 target
                  const percentage = totalTarget ? (totalActualPriceSum / totalTarget) * 100 : 0;
                  sums[index] = percentage.toFixed(2);  // 保证两位小数
                  sums[index] += '%';
                } else {
                  // 其他字段进行累加
                  sums[index] = data.reduce((acc, item) => {
                    return acc + (item[fieldName] || 0);  // 确保字段为空时默认加 0
                  }, 0);
                
                  // 对合计值进行格式化
                  sums[index] = sums[index].toFixed(2);  // 保证两位小数
                
                  // 处理单位
                  if (index === 1 || index === 2) {
                    sums[index] += '元';
                  }
                }
            } else {
              sums[index] = '';
            }
          } else {
            sums[index] = '';  // 如果没有数据，设置为空字符串
          }
        });
        console.log('sums',sums);
        return sums;
      }
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 20px;
}
.box-card2 {
  margin: 20px 0px;
  margin-left: 10px;
}
.card_head {
  display: flex;
  /* width: 170px; */
  align-items: center;
  .title {
    width: 130px;
  }
}

.inputbox {
  width: 270px;
  margin-left: auto;
  .input{
    width: 200px;
  }
  .search{
    width: 70px;
  }
}
// ::v-deep .table-box .el-table__body-wrapper::-webkit-scrollbar{
//   width: 2px;
//   height: 0;
//   color: transparent;
//   display:none;
// } 
::v-deep .el-table__header-wrapper tbody td.el-table__cell, 
::v-deep .el-table__footer-wrapper tbody td.el-table__cell{
    background: #FBEBEB !important;
    color: #FF0000 !important;
}
</style>
