var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticStyle: { "min-width": "1100px" }, attrs: { gutter: 10 } },
    [
      _c(
        "el-col",
        { staticStyle: { padding: "0 15px" }, attrs: { span: 24 } },
        [
          _c(
            "div",
            { staticClass: "df_ac" },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择产品领域", clearable: "" },
                  on: { change: _vm.productChange },
                  model: {
                    value: _vm.classifyNameTwo,
                    callback: function ($$v) {
                      _vm.classifyNameTwo = $$v
                    },
                    expression: "classifyNameTwo",
                  },
                },
                _vm._l(_vm.productOptionss, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.classifyNameTwo,
                      value: item.classifyNameTwo,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-col",
        { attrs: { span: 5 } },
        [
          _c(
            "el-card",
            {
              staticClass: "box-card",
              attrs: { shadow: "hover", "body-style": { padding: "0px" } },
            },
            [
              _c("div", { staticClass: "card_main2" }, [
                _c("div", { staticClass: "bc_data_right2" }, [
                  _c("div", { staticClass: "bcd_item" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("img", {
                        attrs: {
                          src: "https://oss.71go.com/oa0513/yj3.png",
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("周数据")]),
                    ]),
                    _c("div", { staticClass: "bcdi_tle" }, [
                      _vm._v("本周完成业绩"),
                    ]),
                    _c("div", { staticClass: "bcdi_num" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.weekActualPriceSum
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "bcd_item" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("img", {
                        attrs: {
                          src: "https://oss.71go.com/oa0513/yj3.png",
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("今日数据")]),
                    ]),
                    _c("div", { staticClass: "bcdi_tle" }, [
                      _vm._v("今日完成业绩"),
                    ]),
                    _c("div", { staticClass: "bcdi_num hot" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.todayActualPriceSum
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "本周目标业绩",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj3.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 月度数据 "),
                ]
              ),
              _c(
                "div",
                { staticClass: "card_main" },
                [
                  _c("el-progress", {
                    staticClass: "progress_item",
                    attrs: {
                      color: "#817fff",
                      width: 150,
                      "stroke-width": 15,
                      type: "circle",
                      percentage: Number(_vm.personData.monthPercentage),
                    },
                  }),
                  _c("div", { staticClass: "bc_data_right" }, [
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("月度营销目标"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("numberWithCommas")(
                                _vm.personData.monthTaget
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("月度完成业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num hot" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("numberWithCommas")(
                                _vm.personData.monthActualPriceSum
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "日目标业绩",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj3.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 年度数据 "),
                ]
              ),
              _c(
                "div",
                { staticClass: "card_main" },
                [
                  _c("el-progress", {
                    staticClass: "progress_item",
                    attrs: {
                      color: "#ff5f09",
                      width: 150,
                      "stroke-width": 15,
                      type: "circle",
                      percentage: Number(_vm.personData.yearPercentage),
                    },
                  }),
                  _c("div", { staticClass: "bc_data_right" }, [
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("年度营销目标"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("numberWithCommas")(
                                _vm.personData.yearTaget
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("年度完成业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num hot" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("numberWithCommas")(
                                _vm.personData.yearActualPriceSum
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "box-card h260",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "产品领域业绩占比",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj1.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 产品领域业绩占比"),
                ]
              ),
              _c("div", { attrs: { id: "prodrate" } }),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "box-card h340",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "产品专员业绩排名",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj1.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 产品专员业绩排名 "),
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.performanceHandleClick },
                      model: {
                        value: _vm.performanceSortField,
                        callback: function ($$v) {
                          _vm.performanceSortField = $$v
                        },
                        expression: "performanceSortField",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "业绩额", name: "0" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "完成率", name: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { attrs: { id: "performance" } }),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 10 } },
        [
          _c(
            "el-card",
            {
              staticClass: "main-card margint-20",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "签单趋势",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj5.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 签单趋势 "),
                ]
              ),
              _c(
                "div",
                { staticClass: "data_change" },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { size: "mini" },
                      on: { input: _vm.changeChartType },
                      model: {
                        value: _vm.trendData.chartType,
                        callback: function ($$v) {
                          _vm.$set(_vm.trendData, "chartType", $$v)
                        },
                        expression: "trendData.chartType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("日"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("周"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("月"),
                      ]),
                    ],
                    1
                  ),
                  _vm.trendData.chartType == 0
                    ? _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          size: "mini",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                        },
                        on: { change: _vm.setDayChanged },
                        model: {
                          value: _vm.setDay,
                          callback: function ($$v) {
                            _vm.setDay = $$v
                          },
                          expression: "setDay",
                        },
                      })
                    : _vm._e(),
                  _vm.trendData.chartType == 1
                    ? _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          size: "mini",
                          type: "month",
                          placeholder: "选择月",
                          "value-format": "yyyy-MM",
                          format: "yyyy-MM",
                        },
                        on: { change: _vm.setMonthChanged },
                        model: {
                          value: _vm.setMonth,
                          callback: function ($$v) {
                            _vm.setMonth = $$v
                          },
                          expression: "setMonth",
                        },
                      })
                    : _vm._e(),
                  _vm.trendData.chartType == 2
                    ? _c("el-date-picker", {
                        attrs: {
                          clearable: false,
                          size: "mini",
                          type: "year",
                          placeholder: "选择年",
                          "value-format": "yyyy",
                          format: "yyyy",
                        },
                        on: { change: _vm.setYearChanged },
                        model: {
                          value: _vm.setYear,
                          callback: function ($$v) {
                            _vm.setYear = $$v
                          },
                          expression: "setYear",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { attrs: { id: "progress_trend" } }),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "main-card  margint-20 h530",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "产品领域业绩占比",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj5.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 产品领域业绩占比 "),
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.productHandleClick },
                      model: {
                        value: _vm.productSortField,
                        callback: function ($$v) {
                          _vm.productSortField = $$v
                        },
                        expression: "productSortField",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "业绩数据", name: "0" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "退款数据", name: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "sc_prank" },
                _vm._l(_vm.productOptions, function (item, index) {
                  return _c("div", { key: index, staticClass: "scp_item" }, [
                    _c("div", { staticClass: "li_main" }, [
                      _c("div", { staticClass: "r_name" }, [
                        _c("div", {
                          staticClass: "r_name_icon",
                          style: { background: item.color },
                        }),
                        _vm._v(_vm._s(item.classifyNameTwo)),
                      ]),
                      _vm.productSortField == "0"
                        ? _c("div", { staticClass: "black" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("numberWithCommas")(
                                    item.actualPriceSum
                                  )
                                ) +
                                "/"
                            ),
                          ])
                        : _c("div", { staticClass: "black" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("numberWithCommas")(
                                    item.refundPriceSum +
                                      item.depositRefundPriceSum
                                  )
                                ) +
                                "/"
                            ),
                          ]),
                      _vm.productSortField == "0"
                        ? _c("div", { staticClass: "hui" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("numberWithCommas")(
                                    _vm.allActualPriceSum
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("div", { staticClass: "hui" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("numberWithCommas")(_vm.refundSum)
                                ) +
                                " "
                            ),
                          ]),
                    ]),
                    _c("div", { staticClass: "r_sche-right" }, [
                      _c("div", { staticClass: "r_sche" }, [
                        _c("div", {
                          staticClass: "l_inside",
                          style: {
                            backgroundColor: item.color,
                            width:
                              (_vm.getTagetNum(
                                item.actualPriceSum,
                                _vm.allActualPriceSum
                              ) > 100
                                ? 100
                                : _vm.getTagetNum(
                                    item.actualPriceSum,
                                    _vm.allActualPriceSum
                                  )) + "%",
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "r_perf" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getTagetNum(
                                item.actualPriceSum,
                                _vm.allActualPriceSum
                              ) + "%"
                            )
                        ),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "box-card h340",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "",
              },
            },
            [
              _c("PerformanceTable", {
                attrs: {
                  departmentId: _vm.departmentId,
                  classifyNameTwo: _vm.classifyNameTwo,
                  tableData: _vm.performanceTableData,
                  res: "rateTable",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 9 } },
        [
          _vm.trendData && _vm.trendData.startTime && _vm.trendData.endTime
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("DivisionTable", {
                        attrs: {
                          tableData: _vm.divisionTableData,
                          res: "rateTable",
                          inputReady: _vm.inputReady,
                        },
                        on: {
                          DivisionEmit: _vm.DivisionEmit,
                          updateInputReady: _vm.updateInputReady,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }