<template>
  <div class="table">
    <el-card
      shadow="hover"
      :body-style="{ padding: '10px' }"
      class="box-card2"
      header=""
    >
      <div class="card_head" slot="header">
        <div class="title">产品数据明细</div>
        <el-tabs v-model="sortField" @tab-click="handleClick">
          <el-tab-pane label="成交总额" name="0"></el-tab-pane>
          <el-tab-pane label="成交总量" name="1"></el-tab-pane>
        </el-tabs>
        <div class="inputbox">
          <el-input v-model="productName" placeholder="请输入产品名称" class="input" clearable></el-input>
          <el-button type="primary" class="search" @click.stop="handleClick">查询</el-button>
        </div>
      </div>
      <el-table :data="tableData" stripe class="table-box" style="width: 100%;overflow-x:hidden;" height="1330px">
        <el-table-column  label="排名" >
          <template slot-scope="scope">
            {{ scope.$index+1 }}
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" >
        </el-table-column>
        <el-table-column prop="actualPriceSum" label="成交额">
        </el-table-column>
        <el-table-column prop="productCount" label="成交量"> </el-table-column>
        <el-table-column  label="平均售价"> 
          <template slot-scope="scope">
            {{(scope.row.actualPriceSum/scope.row.productCount).toFixed(2) }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sortField: "0",
      productName: "",
    };
  },
  props: {
    tableData: {
      type: Array, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
    },
    inputReady: {
      type: Boolean, // 假设salesmanId是字符串类型
      default: true, // 是否必须传递prop
    },
  },
  watch: {
    // 监听 inputReady 的变化
    inputReady(newVal) {
      console.log('切换领域重置输入框',newVal);
      if (newVal) {
        this.updateInputValue();
      }
    },
  },
  mounted() {
    
  },
  methods: {
    updateInputValue(){
      this.productName=""; // 调用重置函数
      this.$emit('updateInputReady');
      this.$forceUpdate()
    },
    handleClick(tab, event) {
      let data={
        sortField:this.sortField,
        productName:this.productName,
      }
      this.$emit('DivisionEmit',data);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 20px;
}
.box-card2 {
  height: 1428px;
  margin: 20px 0px;
  margin-left: 10px;
}
.card_head {
  display: flex;
  /* width: 170px; */
  align-items: center;
  .title {
    width: 130px;
  }
}
::v-deep .el-tabs .el-tabs__header{
  margin: 0;
}
.inputbox {
  width: 270px;
  margin-left: auto;
  .input{
    width: 200px;
  }
  .search{
    width: 70px;
  }
}
</style>
