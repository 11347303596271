<template>
  <div class="table">
    <el-card
      shadow="hover"
      :body-style="{ padding: '10px' }"
      class="box-card2"
      header=""
    >
      <div class="card_head" slot="header">
        <div class="title">
          <img class="title-icon" src="https://oss.71go.com/qyg-pc/oa/titleIcon.png" alt="">
          <el-tabs v-model="dataDetail" @tab-click="dataDetailClick" style="width: 100%;">
            <el-tab-pane label="产品数据明细" name="0"></el-tab-pane>
            <el-tab-pane label="订单数据明细" name="1"></el-tab-pane>
          </el-tabs>
          <!-- <div class="title-item" >产品数据明细</div>
          <div class="title-item" >订单数据明细</div> -->
        </div>
        <div class="searchbox" v-if="dataDetail==0">
          <el-tabs type="card" v-model="sortField" @tab-click="handleClick">
            <el-tab-pane label="成交总额" name="0"></el-tab-pane>
            <el-tab-pane label="成交总量" name="1"></el-tab-pane>
            <el-tab-pane label="产品单价" name="2"></el-tab-pane>
          </el-tabs>
          <div class="inputbox">
            <el-input v-model="productName" placeholder="请输入产品名称" class="input" clearable></el-input>
            <el-button type="primary" class="search" @click.stop="handleClick">查询</el-button>
          </div>          
        </div>
        <div class="searchbox" v-else>
          <el-tabs type="card" v-model="sortField" @tab-click="dataDetailClick">
            <el-tab-pane label="订单总额" name="0"></el-tab-pane>
          </el-tabs>
          <div class="inputbox">
            <el-input v-model="productName" placeholder="请输入产品名称" class="input" clearable></el-input>
            <el-button type="primary" class="search" @click.stop="dataDetailClick">查询</el-button>
          </div>          
        </div>
      </div>
      <el-table v-if="dataDetail==0" :header-cell-style="hearderStyle" :data="tableData" stripe class="table-box" style="width: 100%;overflow-x:hidden;" height="1330px">
        <el-table-column width="100px"   label="排名" >
          <template slot-scope="scope">
            {{ scope.$index+1 }}
          </template>
        </el-table-column>
        <el-table-column width="200px"  show-overflow-tooltip prop="productName" label="产品名称" >
        </el-table-column>
        <el-table-column prop="actualPriceSum" label="成交额">
        </el-table-column>
        <el-table-column prop="productCount" label="成交量"> </el-table-column>
        <el-table-column  prop="averagePrice" label="平均售价"> </el-table-column>
      </el-table>
      <el-table v-else :header-cell-style="hearderStyle" :data="tableData" stripe class="table-box" style="width: 100%;overflow-x:hidden;" height="1330px">
        <el-table-column width="100px" label="排名" >
          <template slot-scope="scope">
            {{ scope.$index+1 }}
          </template>
        </el-table-column>
        <el-table-column  width="180px" prop="orderCode" label="订单编号" >
        </el-table-column>
        <el-table-column width="100px" prop="price" label="订单总额">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="childSkuName"  label="产品">
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sortField: "0",
      dataDetail: "0",
      productName: "",
    };
  },
  props: {
    dataDetails:{
      type: String, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
    },
    tableData: {
      type: Array, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
    },
    inputReady: {
      type: Boolean, // 假设salesmanId是字符串类型
      default: true, // 是否必须传递prop
    },
  },
  watch: {
    // 监听 inputReady 的变化
    dataDetails(newVal) {
      console.log('dataDetail',newVal);
      if (newVal) {
        this.dataDetail=this.dataDetails
      }
    },
    inputReady(newVal) {
      console.log('切换领域重置输入框',newVal);
      if (newVal) {
        this.updateInputValue();
      }
    },
  },
  mounted() {
    console.log('传入tableData',this.tableData);
    
  },
  methods: {
    hearderStyle(row, rowIndex){
        return 'background: #EFF4FF;color:#1C1F23;text-align:left;font-family: PingFang SC, PingFang SC;font-weight: bold;font-size: 14px;'
    },
    updateInputValue(){
      this.productName=""; // 调用重置函数
      this.$emit('updateInputReady');
      this.$forceUpdate()
    },
    handleClick(tab, event) {
      let data={
        sortField:this.sortField,
        productName:this.productName,
      }
      this.$emit('DivisionEmit',data);
    },
    dataDetailClick(tab, event) {
      console.log('tab',tab);
      console.log('event',event);  
      console.log('dataDetail',this.dataDetail);  
      
      this.$emit('dataDetailTab',this.dataDetail);
      this.sortField="0";
      let data={
        sortField:this.dataDetail,
        productName:this.productName
      }
      this.$emit('dataDetailEmit',data);
    },
  },
};
</script>

<style lang="scss" scoped>

.table {
  margin-top: 20px;
  

}
.box-card2 {
  height: 1428px;
  margin: 20px 0px;
  margin-left: 10px;
}

.card_head {
  
  .title {
    display: flex;
    align-items: center;
    .title-icon{
      width: 32px;
      height: 32px;
    }
    .title-item{
      cursor: pointer;
      margin-right: 6px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }
    .title-item:hover{
      color: #409EFF;
    }
  }
}
::v-deep .el-tabs .el-tabs__header{
  margin: 0;
}
.searchbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.inputbox {
  width: 270px;
  margin-left: auto;
  .input{
    width: 200px;
  }
  .search{
    width: 70px;
  }
}
::v-deep ::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
::v-deep ::-webkit-scrollbar-thumb {
  background-color: #d6d8dd; /* 滑块背景颜色 */
  border-radius: 10px; /* 滑块圆角 */
  transition: background-color 0.3s ease; /* 平滑过渡效果 */
}

::v-deep ::-webkit-scrollbar-track {
  background: #ffffff; /* 滚动条轨道颜色 */
  border-radius: 10px; /* 轨道圆角 */
}

::v-deep ::-webkit-scrollbar-thumb:hover {
  background-color: #c7c9cc; /* 悬停时滑块背景颜色 */
}
</style>
