var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card2",
          attrs: {
            shadow: "hover",
            "body-style": { padding: "10px" },
            header: "",
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "table-box",
              staticStyle: { width: "100%", "overflow-x": "hidden" },
              attrs: {
                data: _vm.tableData,
                stripe: "",
                "max-height": "260px",
                "summary-method": _vm.getSummaries,
                "show-summary": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "salesmanName", label: "专员" },
              }),
              _c("el-table-column", {
                attrs: { prop: "target", label: "目标" },
              }),
              _c("el-table-column", {
                attrs: { prop: "actualPriceSum", label: "业绩额" },
              }),
              _c("el-table-column", {
                attrs: { label: "完成率" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getTagetNum(
                                scope.row.actualPriceSum,
                                scope.row.target
                              )
                            ) +
                            "% "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "排名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }