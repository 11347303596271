var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "min-h-screen" },
    [
      _c(
        "div",
        { staticClass: "df_ac" },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "150px", "margin-right": "10px" },
              attrs: { clearable: "", size: "mini", placeholder: "请选择部门" },
              on: { change: _vm.handleDepartmentChange },
              model: {
                value: _vm.queryData.departmentId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "departmentId", $$v)
                },
                expression: "queryData.departmentId",
              },
            },
            _vm._l(_vm.allDepartment, function (item) {
              return _c("el-option", {
                key: item.departmentId,
                attrs: { label: item.departmentName, value: item.departmentId },
              })
            }),
            1
          ),
          _c("el-date-picker", {
            staticStyle: { width: "210px" },
            attrs: {
              size: "mini",
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              format: "yyyy-MM-dd",
              "value-format": "yyyy-MM-dd",
            },
            on: { change: _vm.handleDateChange },
            model: {
              value: _vm.dateRange,
              callback: function ($$v) {
                _vm.dateRange = $$v
              },
              expression: "dateRange",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "el-card",
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: "https://oss.71go.com/oa0513/yj2.png",
                        alt: "",
                      },
                    }),
                    _vm._v(" 客户来源数据统计"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.handleProductData },
                    },
                    [_vm._v("查看产品分析数据")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(_vm.statsData, function (item, index) {
                          return _c(
                            "el-col",
                            { key: index, attrs: { span: 8 } },
                            [
                              _c(
                                "el-card",
                                {
                                  staticClass: "stat-card",
                                  attrs: { shadow: "hover" },
                                },
                                [
                                  _c("div", { staticClass: "stat-header" }, [
                                    _c("span", { staticClass: "text" }, [
                                      _vm._v(_vm._s(item.label)),
                                    ]),
                                    _c("i", {
                                      class: item.icon,
                                      style: { color: item.color },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "stat-value" }, [
                                    _c("span", [_vm._v(_vm._s(item.value))]),
                                    _c("span", [_vm._v(_vm._s(item.otherNum))]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-card",
                        {
                          staticClass: "chart-card",
                          attrs: { shadow: "hover" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_vm._v("客户类型业务占比")]
                          ),
                          _c("div", { ref: "pieChart", staticClass: "chart" }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { attrs: { shadow: "hover" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("产品分类数据统计"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        stripe: "",
                        border: "",
                        height: "325px",
                      },
                    },
                    [
                      _vm.queryData.departmentId
                        ? _c("el-table-column", {
                            attrs: { prop: "salesmanName", label: "业务员" },
                          })
                        : _c("el-table-column", {
                            attrs: {
                              prop: "departmentName",
                              label: "部门名称",
                            },
                          }),
                      _c("el-table-column", {
                        attrs: { prop: "orderCount", label: "订单量" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "orderPriceSum", label: "销售额" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "customerUnitPrice", label: "客单价" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "oldCustomerCount", label: "老客户" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "networkCustomerCount",
                          label: "网络客户",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "introduceCustomerCount",
                          label: "转介绍",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isShowProduct
        ? _c("product-dialog", {
            ref: "ProductDialog",
            attrs: {
              departmentId: _vm.queryData.departmentId,
              productData: _vm.productData,
            },
            on: { close: _vm.closeDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }