<template>
  <div class="min-h-screen">
    <div class="df_ac">
      <el-select clearable size="mini" style="width:150px;margin-right:10px;" v-model="queryData.departmentId" @change="handleDepartmentChange" placeholder="请选择部门">
        <el-option v-for="item in allDepartment" :key="item.departmentId" :label="item.departmentName" :value="item.departmentId">
        </el-option>
      </el-select>
      <el-date-picker size="mini" style="width:210px" v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
        @change="handleDateChange"></el-date-picker>
    </div>
    <div class="container">

      <el-card>
        <div class="card_head" slot="header">
          <div><img src="https://oss.71go.com/oa0513/yj2.png" alt=""> 客户来源数据统计</div><el-button type="primary" plain @click="handleProductData">查看产品分析数据</el-button>
        </div>
        <el-row :gutter="20">

          <el-col :span="12">
            <el-row :gutter="20">
              <el-col :span="8" v-for="(item, index) in statsData" :key="index">
                <el-card shadow="hover" class="stat-card">
                  <div class="stat-header">
                    <span class="text">{{ item.label }}</span>
                    <i :class="item.icon" :style="{ color: item.color }"></i>
                  </div>
                  <div class="stat-value"><span>{{ item.value }}</span><span>{{ item.otherNum }}</span> </div>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-card shadow="hover" class="chart-card">
              <div slot="header">客户类型业务占比</div>
              <div ref="pieChart" class="chart"></div>
            </el-card>
          </el-col>
        </el-row>
        <el-card shadow="hover">
          <div slot="header" class="clearfix">
            <span class="title">产品分类数据统计</span>
            <!-- <el-input placeholder="搜索产品分类" class="input-search" prefix-icon="el-icon-search"></el-input>
          <el-button type="primary" icon="el-icon-download">导出数据</el-button> -->
          </div>
          <el-table :data="tableData" stripe border style="width: 100%" height="325px">
            <el-table-column v-if="queryData.departmentId" prop="salesmanName" label="业务员"></el-table-column>
            <el-table-column v-else prop="departmentName" label="部门名称"></el-table-column>
            <el-table-column prop="orderCount" label="订单量"></el-table-column>
            <el-table-column prop="orderPriceSum" label="销售额"></el-table-column>
            <el-table-column prop="customerUnitPrice" label="客单价"></el-table-column>
            <el-table-column prop="oldCustomerCount" label="老客户"></el-table-column>
            <el-table-column prop="networkCustomerCount" label="网络客户"></el-table-column>
            <el-table-column prop="introduceCustomerCount" label="转介绍"></el-table-column>
          </el-table>
        </el-card>
      </el-card>
    </div>
    <product-dialog v-if="isShowProduct" ref="ProductDialog" :departmentId="queryData.departmentId" :productData="productData" @close="closeDialog" />
  </div>
</template>

<script>
import ProductDialog from "../dialog/productAnalysis.vue";
import {
  findProductDepartmentInfo,
  findOrderInfo,
  findSalesmanByDepartmentId,
  findDepartmentInfo,
  findProductByDepartmentId,
  findDepartmentAll,
} from "@/api/analysis/data";
import * as echarts from "echarts";
export default {
  components: {
    ProductDialog,
  },
  data() {
    return {
      isShowProduct: false,
      tableData: [],
      dateRange: [],
      queryData: {
        departmentId: null,
        startTime: "",
        endTime: "",
      },
      allDepartment: [],
      productData: [],
      statsData: [],
    };
  },
  created() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    this.queryData.startTime = `${firstDayOfMonth.getFullYear()}-${(
      firstDayOfMonth.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-01`;
    this.queryData.endTime = `${lastDayOfMonth.getFullYear()}-${(
      lastDayOfMonth.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
    this.getfindDepartmentAll();
    this.getAllData();
  },
  mounted() {},
  methods: {
    handleProductData() {
      this.isShowProduct = true;
      this.$nextTick(() => {
        this.$refs.ProductDialog.dialogVisible = true;
      });
    },
    closeDialog() {
      this.isShowProduct = false;
    },
    formatNumber(value) {
      if (!value) return 0;
      return (value / 10000).toFixed(3);
    },
    handleDateChange(value) {
      console.log(value);
      this.queryData.startTime = value[0];
      this.queryData.endTime = value[1];
      this.getAllData();
    },
    async getOrderInfo() {
      let res = await findOrderInfo(this.queryData);
      if (res.code === 200) {
        this.statsData[0].value = res.data.orderCount + this.statsData[0].value;
        this.statsData[1].value =
          this.formatNumber(res.data.orderPriceSum) + this.statsData[1].value;
        this.statsData[2].value =
          res.data.customerUnitPrice + this.statsData[2].value;
        this.statsData[3].value =
          this.formatNumber(res.data.oldCustomerPrice) +
          this.statsData[3].value;
        this.statsData[4].value =
          this.formatNumber(res.data.networkCustomerPrice) +
          this.statsData[4].value;
        this.statsData[5].value =
          this.formatNumber(res.data.introduceCustomerPrice) +
          this.statsData[5].value;
        this.statsData[3].otherNum =
          res.data.oldCustomerCount + this.statsData[3].otherNum;
        this.statsData[4].otherNum =
          res.data.networkCustomerCount + this.statsData[4].otherNum;
        this.statsData[5].otherNum =
          res.data.introduceCustomerCount + this.statsData[5].otherNum;
        this.initPieChart(res.data);
      }
    },
    async getfindDepartmentAll() {
      let res = await findDepartmentAll();
      if (res.code == 200) {
        this.allDepartment = res.data.filter(
          (item) =>
            item.departmentName &&
            !item.departmentName.includes("专业产品部") &&
            !item.departmentName.includes("互联网产品组")
        );
      }
    },
    async handleDepartmentChange() {
      this.getAllData();
    },
    initPieChart(data) {
      this.pieChart = echarts.init(this.$refs.pieChart);
      this.pieChart.setOption({
        tooltip: { trigger: "item", formatter: "{b}: {c} ({d}%)" },
        legend: { orient: "vertical", right: 10, top: "center" },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            data: [
              { value: data.oldCustomerCount, name: "老客户" },
              { value: data.networkCustomerCount, name: "网络客户" },
              { value: data.introduceCustomerCount, name: "转介绍客户" },
            ],
          },
        ],
        color: ["#5470c6", "#9fe080", "#fac858"],
      });
    },
    async getDepartmentData() {
      let res;
      if (this.queryData.departmentId) {
        res = await findSalesmanByDepartmentId(this.queryData);
      } else {
        res = await findDepartmentInfo(this.queryData);
      }
      if (res.code === 200) {
        this.tableData = res.data;
      }
    },
    async getProductData() {
      let res;
      if (this.queryData.departmentId) {
        res = await findProductByDepartmentId(this.queryData);
      } else {
        res = await findProductDepartmentInfo(this.queryData);
      }
      if (res.code === 200) {
        this.productData = res.data;
      }
    },
    getAllData() {
      this.reSetData();
      this.getOrderInfo();
      this.getDepartmentData();
      this.getProductData();
    },
    reSetData() {
      this.statsData = [
        {
          label: "订单总量",
          value: "单",
          icon: "el-icon-document",
          color: "#409EFF",
          otherNum: null,
        },
        {
          label: "销售总额",
          value: "万",
          icon: "el-icon-money",
          color: "#67C23A",
          otherNum: null,
        },
        {
          label: "客单均价",
          value: "元",
          icon: "el-icon-pie-chart",
          color: "#E6A23C",
          otherNum: null,
        },
        {
          label: "老客户",
          value: "万",
          icon: "el-icon-user",
          color: "#F56C6C",
          otherNum: "单",
        },
        {
          label: "网络客户",
          value: "万",
          icon: "el-icon-data-line",
          color: "#409EFF",
          otherNum: "单",
        },
        {
          label: "转介绍",
          value: "万",
          icon: "el-icon-refresh",
          color: "#909399",
          otherNum: "单",
        },
      ];
    },
  },
};
</script>
<style >
.stat-card .el-card__body {
  padding: 10px;
}
</style>
<style lang="scss" scoped>
.df_ac {
  display: flex;
  align-items: center;
  position: fixed;
  top: 12px;
  left: 280px;
  z-index: 999;
}
.card_head {
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
}
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 10px;
}
.input-search {
  margin-right: 15px;
  width: 250px;
}
.stat-card {
  transition: all 0.3s ease;
  border-radius: 10px;
  margin-bottom: 20px;
}
.stat-card:hover {
  transform: translateY(-5px);
}
.stat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text {
    color: #333;
    font-size: 16px;
  }
}
.stat-value {
  font-family: pingfang;
  font-weight: 500;
  font-size: 23px;
  color: #333;
  margin-top: 3px;
  text-align: center;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span {
    margin-left: 5px;
    &:nth-last-child(1) {
      margin-top: 5px;
      font-size: 16px;
      color: #b5b0b0;
    }
  }
}
.chart-card {
  border-radius: 10px;
}
.chart {
  height: 213px;
}
</style>
