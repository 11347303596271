<template>
  <div class="app-container data_bg">
    <img class="logo" src="https://oss.71go.com/oa0513/logo.png" alt="">
    <div class="fix_nav">
      <div class="user-info">
        <div class="flex_Ca">
          <img class="avatar" src="https://oss.71go.com/pcvip0404/user/pt.png" alt="User Avatar">
          <span class="name">{{ dataSource.salesmanName }}</span>

        </div>
        <span class="update-info">
          <el-button style="margin-right:20px" size="mini" @click="infoVisible = true">规则说明</el-button>
          <span class="last-update">最近一次更新时间：{{ lastUpdate }}</span>
          <span class="next-update">下一次更新时间：{{ nextUpdate }}</span>
        </span>

      </div>
    </div>
    <el-tabs v-model="activeName" style="height:100%;  padding-top: 40px;" tab-position="left" type="border-card" @tab-click="addPoint('点击'+tabs[activeName-1]+'报表')">
      <el-tab-pane v-if="purviewList.includes('1')" label="个人" name="1">
        <span slot="label"><i class="el-icon-user"></i> 个人</span>
        <personData v-if="activeName === '1'" :salesmanId="dataSource.salesmanId"></personData>

      </el-tab-pane>
      <el-tab-pane v-if="purviewList.includes('2')" label="部门" name="2">
        <span slot="label"><i class="el-icon-date"></i> 部门</span>

        <departmentData v-if="activeName === '2'" :departmentId="dataSource.departmentId" @addPoint="addPoint"></departmentData>
      </el-tab-pane>
      <el-tab-pane v-if="purviewList.includes('3')" label="战区" name="3">
        <span slot="label"><i class="el-icon-files"></i> 战区</span>

        <warzoneData v-if="activeName === '3'" :zoneId="dataSource.zoneId" @addPoint="addPoint"></warzoneData>
      </el-tab-pane>
      <el-tab-pane v-if="purviewList.includes('4')" label="集团" name="4">
        <span slot="label"><i class="el-icon-house"></i> 集团</span>

        <groupData v-if="activeName === '4'" @addPoint="addPoint"></groupData>
      </el-tab-pane>
      <el-tab-pane v-if="purviewList.includes('5')" label="订单" name="5">
        <span slot="label"><i class="el-icon-s-order"></i> 订单</span>
        <orderData v-if="activeName === '5'"></orderData>
      </el-tab-pane>
      <el-tab-pane v-if="purviewList.includes('6')" label="榜单" name="6">
        <span slot="label"><i class="el-icon-star-off"></i> 榜单</span>
        <rankData v-if="activeName === '6'"></rankData>
      </el-tab-pane>
      <el-tab-pane v-if="purviewList.includes('7')" label="产品个人" name="7">
        <span slot="label"> 产品个人</span>
        <PersonData2 v-if="activeName === '7'" :salesmanId="dataSource.salesmanId"></PersonData2>
        <!-- <PersonData2 :salesmanId="dataSource.salesmanId"  v-if="activeName === '7'"></PersonData2> -->
      </el-tab-pane>

      <el-tab-pane v-if="purviewList.includes('8')" label="产品看板" name="8">
        <span slot="label"> 产品看板</span>
        <productsDivision v-if="activeName === '8'" :departmentId="257"></productsDivision>
        <!-- <productsDivision  :departmentId="dataSource.departmentId" v-if="activeName == 8"></productsDivision> -->
      </el-tab-pane>
       <el-tab-pane label="客户数据" name="9">
        <span slot="label">
         客户数据
        </span>
        <productData v-if="activeName === '9'" :departmentId="257"></productData>
      </el-tab-pane>
      <el-tab-pane label="活动" name="10">
        <span slot="label">
          <i class="el-icon-star-off"></i> 活动
        </span>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :visible.sync="infoVisible" center title="司盟企服营销数据看板计算说明">
      <div>
        <h4>前言：</h4>
        <p>
          数据看板以司盟企服全平台订单数据为基础，根据SCRM系统内的订单关联客户、订单关联客服人员等数据进行多维度的统计和展示，便于客服人员、各层级管理层了解营销工作目标达成进度，便于支持各层级管理层组织策略建设、方案建设和管理经营决策。</p>

        <h4>数据说明：</h4>

        <h5>一、数据结果应用</h5>

        <ul>
          <strong>荣誉评比:</strong>
          <li>个人维度: 评选“销冠”等荣誉.</li>
          <li>团队维度: 评选团队冠军、亚军等.</li>
          <li>特定活动: 阶段性营销活动的个人或团队冠军评比.</li>
        </ul>

        <ul>
          <strong>考核评估:</strong>
          <li>绩效考核: 基于业绩数据进行员工绩效评估.</li>
          <li>激励奖金: 根据业绩达成情况分配奖金.</li>
          <li>职级晋级: 根据业绩达成评估职级升降考核.</li>
        </ul>

        <h5>二、数据意义和计算规则</h5>
        <ol>
          <strong>业绩目标</strong>
          <li>
            <p>数据意义及应用: 以集团每月计划下达的目标为准，作为当月荣誉评比和考核评估的目标数据.</p>
            <p>计算规则: 日目标 = 月目标 / 本月工作日天数; 周目标 = 日目标 * 本周工作天数</p>
          </li>
          <strong>本月有效业绩</strong>
          <li>
            <p>数据意义及应用: 指当月有效的新签订单业绩，应用于各类荣誉评比.</p>
            <p>计算规则: 当月新签单业绩 - 当月订单当月退单的业绩.</p>
          </li>
          <strong>退单业绩</strong>
          <li>
            <p>
              数据意义及应用：用于核算当月个人/团队/战区/集团的实际业绩，退单业绩无论是因为主观原因还是客观原因造成的，队员、管理层都需要对此结果负责。</p>
            <p>计算规则: 所有在本月审批结束的退单业绩.</p>
          </li>
          <strong>实际业绩</strong>
          <li>
            <p>数据意义及应用: 指当月新签在扣除所有当月所有退单业绩后的总业绩，应用于各类考核评估.</p>
            <p>计算规则: 当月新签单业绩 - 当月所有退单的业绩.</p>
          </li>
          <strong>签单产品占比等其他明细数据</strong>
          <li>
            <p>
              签单产品分类为该订单内的具体产品在产品中台的一二级分类（产品一级分类：地域（海外、国内）；产品二级分类：产品专业领域（如知识产权、财税审计等））。</p>
          </li>
        </ol>
      </div>

      <div slot="footer">
        <el-button @click="infoVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addRecord, findSalesmanAll } from '@/api/analysis/data'
import personData from './components/personData.vue'
import productsDivision from './components/productsDivision.vue'
import productIndividual from './components/productIndividual.vue'
import departmentData from './components/departmentData.vue'
import warzoneData from './components/warzoneData.vue'
import groupData from './components/GroupData.vue'
import orderData from './components/orderData.vue'
import rankData from './components/rankData.vue'
import PersonData2 from './components/PersonData2.vue'
import productData from './components/productData.vue'

export default {
  components: {
    productsDivision,
    productIndividual,
    personData,
    departmentData,
    warzoneData,
    groupData,
    orderData,
    rankData,
    PersonData2,
    productData
  },
  data() {
    return {
      infoVisible: false,
      activeName: "",
      salesmanId: "",
      dataSource: {},

      tabs: ["个人", "部门", "战区", "集团", "订单", "榜单","产品个人","企服产品看板","产品数据","活动"],
      dataLists: [],
      times: 0,
      randomInt: 0,
      updateIntervalMinutes: 30, // 更新间隔时间（分钟）
      lastUpdate: "",
      nextUpdate: "",
      intervalId: null,
      productShow: true,
      //可见范围
      purviewList: ["-1"],
    };
  },
  created() {
    this.getSalesmanAll(this.$route.query.salesmanId);
    this.changeBrowserZoom(0.85);
    this.updateTimes(); // 初始更新时间

    // 设置定时器每分钟检查一次是否需要更新时间
    this.intervalId = setInterval(() => {
      this.getSalesmanAll(this.$route.query.salesmanId);
      this.updateTimes();
    }, 1800000);
  },
  destroyed() {
    clearInterval(this.intervalId); // 清除定时器
  },

  methods: {
    addPoint(row) {
      console.log(row);
      if (row === "点击活动报表") {
         window.open('https://sale.simengqifu.com/Activity', "_blank");
      }
      let data = {
        salesmanId: this.dataSource.salesmanId,
        salesmanName: this.dataSource.salesmanName,
        tableName: row,
        clickCount: 1,
      };
      addRecord(data);
    },
    updateTimes() {
      let currentDate = new Date();
      let nearestUpdateDate = new Date(currentDate);
      nearestUpdateDate.setHours(0, 0, 0, 0); // 设置为当天凌晨

      while (nearestUpdateDate.getTime() <= currentDate.getTime()) {
        nearestUpdateDate.setMinutes(nearestUpdateDate.getMinutes() + 30); // 每30分钟更新一次
      }

      // 减去半个小时
      nearestUpdateDate.setMinutes(nearestUpdateDate.getMinutes() - 30);

      this.lastUpdate = `${nearestUpdateDate.getFullYear()}年${
        nearestUpdateDate.getMonth() + 1
      }月${nearestUpdateDate.getDate()}日 ${String(
        nearestUpdateDate.getHours()
      ).padStart(2, "0")}:${String(nearestUpdateDate.getMinutes()).padStart(
        2,
        "0"
      )}`;

      let nextUpdateDate = new Date(nearestUpdateDate);
      nextUpdateDate.setMinutes(nextUpdateDate.getMinutes() + 30);

      // 减去半个小时
      nextUpdateDate.setMinutes(nextUpdateDate.getMinutes());

      this.nextUpdate = `${nextUpdateDate.getFullYear()}年${
        nextUpdateDate.getMonth() + 1
      }月${nextUpdateDate.getDate()}日 ${String(
        nextUpdateDate.getHours()
      ).padStart(2, "0")}:${String(nextUpdateDate.getMinutes()).padStart(
        2,
        "0"
      )}`;
    },
    getTimes() {
      if (this.times == 5) {
        this.randomInt = Math.floor(Math.random() * 11);
        this.times = 0;
        return this.dataLists[this.randomInt];
      } else {
        return this.dataLists[this.randomInt];
      }
    },
    async getSalesmanAll(salesmanId) {
      let res = await findSalesmanAll({ salesmanId: salesmanId });
      if (res.code === 200) {
        console.log();
        if (res.data.length > 0 && (res.data[0].zoneName.includes('售前客服')
          || res.data[0].zoneName.includes('项目部')
          || res.data[0].zoneName.includes('售后客服'))) {
          this.dataSource = res.data[0] || {};
          this.activeName = "1";
        } else {
          this.activeName = "2";
          // this.productShow = false;
          //设置查询默认值
          this.dataSource = {
            salesmanId: res.data[0].salesmanId || "11111111",
            salesmanName: res.data[0].salesmanName || "未知用户",
            zoneId: 246,
            zoneName: "四战区",
            departmentId: 256,
            departmentName: "客服八部",
          };
        }
        //设置可见范围
        this.purviewList = this.findPurview(res.data[0]);
      }
    },
    changeBrowserZoom(zoomLevel) {
      const devicePixelRatio = window.devicePixelRatio || 1;
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      if (screenHeight > 800) {
        zoomLevel = 1;
      }
      if (devicePixelRatio == 1.5) {
        zoomLevel = 0.6;
      }
      if (devicePixelRatio == 1.25) {
        zoomLevel = 0.75;
      }
      if (devicePixelRatio == 1.1) {
        zoomLevel = 0.9;
      }
      const wholePage = document.documentElement;
      wholePage.style.zoom = zoomLevel;
    },
    //获取看板权限
    findPurview(salesmanInfo) {
      //定义可看范围
      let departmentList = ["总裁办", "人力资源部", "专业产品部", "财务部"];
      //可看table
      let purviewList = [];

      //产品部门看板 8 可见范围（人资、财务、总裁办，产品部且是领导可见）
      if (
        salesmanInfo &&
        departmentList.includes(salesmanInfo.departmentName)
      ) {
        purviewList.push("8");
      }
      //产品个人看板 7 可见范围（产品部人员）
      // console.log('salesmanInfo.departmentName', salesmanInfo.departmentName)
      if (salesmanInfo && salesmanInfo.departmentName === "专业产品部") {
        purviewList.push("7");
      }

      if (salesmanInfo && salesmanInfo.salesmanId == 13031901) {
        purviewList.push('7')
        purviewList.push('8')
      }
      //榜单看板 6 可见范围（全部）
      purviewList.push("6");

      //订单看板 5 可见范围（全部）
      purviewList.push("5");

      //集团看板 4 可见范围（全部）
      purviewList.push("4");

      //战区看板 3 可见范围（全部）
      purviewList.push("3");

      //部门看板 2 可见范围（全部）
      purviewList.push("2");

      //个人看板 1 可见范围（全部）
      if (
        salesmanInfo &&
        salesmanInfo.departmentName !== "专业产品部" &&
        (salesmanInfo.zoneName.includes('售前客服')
          || salesmanInfo.zoneName.includes('项目部')
          || salesmanInfo.zoneName.includes('售后客服'))
      ) {
        purviewList.push("1");
      }
      console.log("purviewList", purviewList);
      return purviewList;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  padding: 0;
  position: relative;
  height: 100%;
}

.logo {
  position: absolute;
  top: 20px;
  left: 22px;
  z-index: 999;
}
::v-deep ::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
::v-deep ::-webkit-scrollbar-thumb {
  background-color: #d6d8dd; /* 滑块背景颜色 */
  border-radius: 10px; /* 滑块圆角 */
  transition: background-color 0.3s ease; /* 平滑过渡效果 */
}

::v-deep ::-webkit-scrollbar-track {
  background: #ffffff; /* 滚动条轨道颜色 */
  border-radius: 10px; /* 轨道圆角 */
}

::v-deep ::-webkit-scrollbar-thumb:hover {
  background-color: #c7c9cc; /* 悬停时滑块背景颜色 */
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  background: url("https://oss.71go.com/oa0513/oadatabg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 10px 15px;
  overflow: auto;
}

::v-deep .el-tabs--left.el-tabs--border-card .el-tabs__header.is-left {
  padding-top: 46px;
  margin-right: -6px;
  background: #fff;
  height: 100vh;
}

::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background: #f2f6ff;
}

::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  height: 50px;
  line-height: 50px;
}

.fix_nav {
  border-left: 1px solid #dfe4ed;
  height: 50px;
  width: calc(100% - 143px);
  position: fixed;
  top: 0;
  left: 143px;
  background: #fff;
  z-index: 999;
  padding: 0 20px;

  .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #333;
    height: 100%;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .name {
      font-weight: bold;
      margin-right: 10px;
    }

    .location-time {
      display: flex;
      align-items: center;

      .location {
        margin-right: 10px;
      }

      .datetime {
        margin-right: 100px;
      }
    }

    .update-info {
      display: flex;
      align-items: center;
      color: red;

      .last-update,
      .next-update {
        margin-right: 10px;

        .highlight {
          background-color: blue;
          color: white;
          padding: 0 5px;
        }
      }
    }
  }
}

.flex_Ca {
  display: flex;
  align-items: center;
}

li {
  list-style-type: none; /* 去掉 li 前面的默认点 */
}

.wrapper {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h4 {
  font-size: 1.3em;
  color: #333;
}

h5 {
  font-size: 1.2em;
  color: #555;
}

ul,
ol {
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}
</style>
