<template>
  <div>
    <div class="df_ac">
      <el-date-picker size="mini" style="width:210px" v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
        @change="handleDateChange"></el-date-picker>

    </div>
    <transition name="fade">
      <div class="rank_bg" v-if=" tableData.length > 0" ref="capture">
        <el-button class="fix_btn" v-show="!generatePictures" @click="html2canvas">生成图片</el-button>
        <img class="tle" src="https://oss.71go.com/oa0513/lhb.png" alt="">
        <transition name="fade">
          <div class="rank_ten">
            <div class="rt_top">
              <div class="rtt_two">
                <div class="rtt_head"></div>
                <img class="rtt_img" :src="firstThreeItems[1].avatar" alt="">
                <div class="rrt_info">
                  <div class="name">{{firstThreeItems[1].salesmanName}}</div>
                  <div class="data">目标：{{firstThreeItems[1].monthlyTarget | numberWithCommas}}<br />有效业绩：{{firstThreeItems[1].effectivePrice | numberWithCommas}}</div>
                </div>
              </div>
              <div class="rtt_one">
                <div class="rtt_head"></div>
                <img class="rtt_img" :src="firstThreeItems[0].avatar" alt="">
                <div class="rrt_info">
                  <div class="name">{{firstThreeItems[0].salesmanName}}</div>
                  <div class="data">目标：{{firstThreeItems[0].monthlyTarget | numberWithCommas}}<br />有效业绩：{{firstThreeItems[0].effectivePrice | numberWithCommas}}</div>
                </div>
              </div>
              <div class="rtt_three">
                <div class="rtt_head"></div>
                <img class="rtt_img" :src="firstThreeItems[2].avatar" alt="">
                <div class="rrt_info">
                  <div class="name">{{firstThreeItems[2].salesmanName}}</div>
                  <div class="data">目标：{{firstThreeItems[2].monthlyTarget | numberWithCommas}}<br />有效业绩：{{firstThreeItems[2].effectivePrice | numberWithCommas}}</div>
                </div>
              </div>

            </div>
            <div class="rt_bottom">
              <div class="rtb_item" v-for="item in itemsFromToTen" :key="item.salesmanId">
                <div class="num_bg">{{item.groupRanking}}</div>
                <div class="rtt_head"></div>
                <img class="rtt_img" :src="item.avatar" alt="">
                <div class="rrt_info">
                  <div class="name">{{item.salesmanName}}</div>
                  <div class="data">目标：{{item.monthlyTarget | numberWithCommas}}<br />有效业绩：{{item.effectivePrice | numberWithCommas}}<br />距上位业绩：{{item.previousDifference | numberWithCommas}}</div>
                </div>
              </div>
            </div>
            <div class="tr_table">
              <el-table :data="itemsFromToEnd" tripe :height="zoomHeight" :header-cell-style="{ background: '#f8f8f9', color: '#333333' }" style="width: 100%">
                <el-table-column align="center" prop="groupRanking" label="本月排名"></el-table-column>
                <el-table-column align="center" prop="salesmanName" label="姓名">

                  <template #header>
                    姓名<br />
                    <el-input v-if="!generatePictures" clearable size="mini" v-model="filterSalesmanName" placeholder="请输入搜索姓名" />
                  </template>
                  <template slot-scope="scope">
                    {{scope.row.salesmanName}}
                  </template>

                </el-table-column>
                <el-table-column align="center" prop="monthlyTarget" label="本月目标"></el-table-column>
                <el-table-column label="月有效业绩数据" width="120" :cell-style="{ background: '#f0f8ff' }" align="center">
                  <template slot-scope="{ row }">
                    <div style="font-weight:bold;color:#ff4545">
                      {{ parseFloat(row.effectivePrice).toFixed(1)  | numberWithCommas}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="200px" align="center" prop="effectiveAchievement" label="本月有效业绩完成率">
                  <template slot-scope="scope">
                    <el-progress :text-inside="true" :format="_format((scope.row.effectiveAchievement * 100).toFixed(1))" :stroke-width="22" color="#ff6e22"
                      :percentage="Number((scope.row.effectiveAchievement * 100).toFixed(1)>100?100:(scope.row.effectiveAchievement * 100).toFixed(1))"></el-progress>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="previousDifference" label="距上位业绩差额"></el-table-column>
                <el-table-column prop="departmentName" label="部门" width="130" align="center">
                  <template #header>
                    <el-select clearable size="mini" v-model="filterDepartment" placeholder="部门">
                      <el-option v-for="item in allDepartment" :key="item.departmentId" :label="item.departmentName" :value="item.departmentId">
                      </el-option>
                    </el-select>
                  </template>
                  <template slot-scope="scope">
                    {{scope.row.departmentName}}
                  </template>

                </el-table-column>
                <el-table-column align="center" prop="zoneName" label="战区"></el-table-column>
                <el-table-column align="center" prop="lastMonthRanking" label="上月排名"></el-table-column>
              </el-table>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";

import html2canvas from "html2canvas";
import { findRankingList, findDepartmentAll } from "@/api/analysis/data";
export default {
  data() {
    return {
      allDepartment: [],
      tableData: [
        // Add more data objects as needed
      ],
      trendData: {},
      zoomHeight: 353,
      recordHeight: 353,
      filterDepartment: "",
      generatePictures: false,
      filterSalesmanName: "",
      dateRange: [],
    };
  },
  computed: {
    // First three items from tableData
    firstThreeItems() {
      return this.tableData.slice(0, 3);
    },
    // Items from index 4 to 10
    itemsFromToTen() {
      return this.tableData.slice(3, 10);
    },
    // Items from index 11 to the last one
    itemsFromToEnd() {
      let arr = this.tableData.slice(10);
      if (!this.filterDepartment && !this.filterSalesmanName) {
        return arr;
      } else {
        return arr.filter((item) => {
          return (
            (!this.filterDepartment ||
              item.departmentId == this.filterDepartment) &&
            item.salesmanName.includes(this.filterSalesmanName)
          );
        });
      }
    },
  },
  created() {
    this.getList();
    this.getfindDepartmentAll();
    const devicePixelRatio = window.devicePixelRatio || 1;
    if (devicePixelRatio == 1.5) {
      this.zoomHeight = 450;
      this.recordHeight = 450;
    }
    if (devicePixelRatio == 1.25) {
      this.zoomHeight = 410;
      this.recordHeight = 410;
    }
    if (devicePixelRatio == 1.1) {
      this.zoomHeight = 370;
      this.recordHeight = 370;
    }
  },
  methods: {
    uploadFileBase64ByParam(posterImageUrl) {
      if (window.ClipboardItem) {
        // 使用 ClipboardItem
        this.copyImageToClipboard(posterImageUrl);
        return false;
      }
      // 创建一个新的 FormData 对象
      const formData = new FormData();
      // 添加参数到 FormData 对象

      formData.append("data", posterImageUrl);

      formData.append("isAll", true);
      let baseUrl = process.env.VUE_APP_BASE_API;
      let url = baseUrl + "/file/simeng/api/uploadFileBase64ByParam";
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // 处理响应数据
          if (response.data.code == 200) {
            // 使用其他方式来复制图片
            window.open(response.data.data.urlPath);

            this.zoomHeight = this.recordHeight;
          }
        })
        .catch((error) => {
          // 处理错误
          console.log("处理错误", error);
        });
    },
    html2canvas() {
      if (this.generatePictures) {
        return false;
      }
      this.generatePictures = true;
      this.zoomHeight = 72 + this.itemsFromToEnd.length * 44;
      let that = this;
      this.$nextTick(() => {
        const element = that.$refs.capture; // 获取DOM元素
        html2canvas(element, {
          useCORS: true,
          allowTaint: true,
          timeout: 1000,
          taintTest: true,
        }).then((canvas) => {
          that.uploadFileBase64ByParam(canvas.toDataURL("image/png"));
          that.generatePictures = false;
        });
      });
    },
    async copyImageToClipboard(imageSrc) {
      try {
        // 创建一个新的 Image 对象
        const response = await fetch(imageSrc);
        const blob = await response.blob();
        const item = new ClipboardItem({ "image/png": blob }); // 假设图片格式为 PNG

        // 将图片添加到剪切板
        await navigator.clipboard.write([item]);
        alert("图片已成功复制到剪切板！");
        this.zoomHeight = this.recordHeight;
      } catch (error) {
        console.error("复制图片到剪切板失败:", error);
        alert("复制失败，请检查控制台查看错误信息。");
        this.zoomHeight = this.recordHeight;
      }
    },
    async getfindDepartmentAll() {
      let res = await findDepartmentAll();
      if (res.code == 200) {
        this.allDepartment = res.data.filter(
          (item) =>
            item.departmentName && !item.departmentName.includes("专业产品部") &&
            !item.departmentName.includes("互联网产品组")
        );
      }
    },
    _format(value) {
      return () => {
        return value + "%";
      };
    },
    async getList() {
      const currentDate = new Date();
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      this.trendData.startTime = `${firstDayOfMonth.getFullYear()}-${(
        firstDayOfMonth.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-01`;
      this.trendData.endTime = `${lastDayOfMonth.getFullYear()}-${(
        lastDayOfMonth.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
      this.dateRange = [this.trendData.startTime, this.trendData.endTime];
      this.getData(this.trendData);
    },
    async getData(data) {
      let res = await findRankingList(data);
      if (res.code == 200) {
        this.tableData = res.data;
      }
    },
    handleDateChange(value) {
      console.log(value);
      this.trendData.startTime = value[0];
      this.trendData.endTime = value[1];
      this.getData(this.trendData);
    },
  },
};
</script>

<style lang="scss" scoped>
.df_ac {
  display: flex;
  align-items: center;
  position: fixed;
  top: 12px;
  left: 280px;
  z-index: 999;
}
.fade-enter-active,
.fade-leave-active {
  transition: height 0.1s ease-in-out;
}

.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}
.fade-enter-to {
  opacity: 1;
  transform: translateY(0px);
}
.fade-leave {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
  transform: translateY(0px);
}
.fix_btn {
  position: absolute;
  right: 100px;
  top: 50px;
}
.rank_bg {
  transition: all 2s ease-in-out; /* Add a smooth transition effect for height changes */
  min-width: 1200px;
  box-sizing: border-box;
  margin: 10px;
  padding-bottom: 17px;
  font-family: PingFang SC, PingFang SC;
  border-radius: 10px;

  background: url("https://oss.71go.com/oa0513/bg.png");
  background-size: cover;
  overflow: hidden;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .tle {
    width: 500px;
    margin: 15px auto 30px;
  }
  .rank_ten {
    display: flex;
    flex-direction: column;
    width: 88%;
    .rt_top {
      display: flex;
      justify-content: center;
      align-items: baseline;
      color: #333;
      .rtt_one {
        position: relative;
        width: 230px;
        height: 190px;
        background: url(https://oss.71go.com/oa0513/rb1.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 2px 0 20px;
        .rrt_info {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          bottom: 50px;
          left: 30px;
          text-align: center;
          .name {
            font-weight: bold;
            margin-bottom: 5px;
            margin-left: 27px;
            font-size: 20px;
          }
          .data {
            color: #666;
            font-size: 15px;
            text-align: left;
            font-size: 16px;
          }
        }
        .rtt_head {
          width: 100px;
          height: 128px;
          position: absolute;
          top: -38px;
          left: 50%;
          margin-left: -55.5px;
          background: url(https://oss.71go.com/oa0513/rq1.png);
          background-size: 100%;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
        }
        .rtt_img {
          width: 59px;
          border-radius: 50%;
          margin-top: 15px;
          position: absolute;
          top: -25px;
          left: 80px;
          z-index: 1;
        }
      }
      .rtt_two {
        position: relative;
        width: 195px;
        height: 186px;
        background: url("https://oss.71go.com/oa0513/rb2.png");
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(5px);
        .rrt_info {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          bottom: 39px;
          left: 36px;
          text-align: center;
          .name {
            font-weight: bold;
            margin-bottom: 10px;
            margin-left: 10px;
            font-size: 16px;
          }
          .data {
            color: #666;
            text-align: left;
            font-size: 13px;
          }
        }
        .rtt_head {
          width: 90px;
          height: 101px;
          position: absolute;
          top: -30px;
          left: 50%;
          margin-left: -44px;
          background: url("https://oss.71go.com/oa0513/rq2.png");
          background-size: 100%;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
        }
        .rtt_img {
          width: 57px;
          border-radius: 50%;
          position: absolute;
          top: -2px;
          left: 70px;
          z-index: 1;
        }
      }
      .rtt_three {
        position: relative;
        width: 190px;
        height: 190px;
        background: url("https://oss.71go.com/oa0513/rb3.png");
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(20px);
        .rrt_info {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          bottom: 52px;
          left: 38px;
          text-align: center;
          .name {
            font-weight: bold;
            margin-bottom: 7px;
            margin-left: 18px;
            font-size: 16px;
          }
          .data {
            color: #666;
            text-align: left;
            font-size: 13px;
          }
        }
        .rtt_head {
          width: 86px;
          height: 86px;
          position: absolute;
          top: -22px;
          left: 50%;
          margin-left: -40px;
          background: url(https://oss.71go.com/oa0513/rq3.png);
          background-size: 100%;
          background-repeat: no-repeat;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          z-index: 2;
        }
        .rtt_img {
          width: 55px;
          border-radius: 50%;
          position: absolute;
          top: 5px;
          left: 70px;
          z-index: 1;
        }
      }

      .rtt_one:hover {
        transition: all 0.3s ease-in-out;
        transform: translateY(-10px);
        cursor: pointer;
      }
      .rtt_two:hover {
        transition: all 0.3s ease-in-out;
        transform: translateY(-5px);
        cursor: pointer;
      }
      .rtt_three:hover {
        transition: all 0.3s ease-in-out;
        transform: translateY(10px);
        cursor: pointer;
      }
    }
    .rt_bottom {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 13px;
      .num_bg {
        position: absolute;
        right: 20px;
        font-size: 53px;
        font-weight: bold;
        top: 20px;
        color: #fff;
      }
      .rtb_item {
        position: relative;
        width: 164px;
        height: 126px;
        background: url(https://oss.71go.com/oa0513/rb4.png);
        background-size: cover;
        background-repeat: no-repeat;
        .rrt_info {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          bottom: 13px;
          left: 17px;
          text-align: center;
          min-width: 114px;

          .name {
            font-weight: bold;
            margin-bottom: 2px;
            margin-left: 6px;
            font-size: 16px;
          }
          .data {
            text-align: left;
            font-size: 13px;
            color: #666;
          }
        }
        .rtt_head {
          width: 80px;
          height: 80px;
          position: absolute;
          top: -35px;
          left: 50%;
          margin-left: -45px;
          background: url(https://oss.71go.com/oa0513/rq4.png);
          background-size: 100%;
          background-repeat: no-repeat;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          z-index: 2;
        }
        .rtt_img {
          width: 48px;
          border-radius: 50%;
          position: absolute;
          top: -23px;
          left: 53px;
          z-index: 1;
        }
      }
      .rtb_item:hover {
        transition: all 0.3s ease-in-out;
        transform: translateY(-10px);
        cursor: pointer;
      }
    }
    .tr_table {
      min-width: 1200px;
    }
  }
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px; /*滚动条高度*/
  z-index: 999;
}
::v-deep .el-progress-bar__innerText {
  color: #fff;
  /* -webkit-text-stroke: 1px white; */
  text-shadow: -1px -1px 0 #ff6e22, 1px -1px 0 #ff6e22, -1px 1px 0 #ff6e22,
    1px 1px 0 #ff6e22;
}
/*定义滑块 内阴影+圆角*/
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  box-shadow: 0px 1px 3px #ff6e22 inset; /*滚动条的内阴影*/
  border-radius: 10px; /*滚动条的圆角*/
  background-color: #ff6e22; /*滚动条的背景颜色*/
  cursor: pointer;
  width: 3px;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 3px; /* 设置滚动条宽度 */
}
</style>