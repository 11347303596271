var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "产品分析数据",
            width: "80%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
            _c(
              "div",
              { staticClass: "table_box" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    attrs: { data: _vm.productData, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "产品分类",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderCount",
                        label: "订单量",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderPriceSum",
                        label: "销售额",
                        align: "center",
                      },
                    }),
                    _vm._l(_vm.departments, function (dept, index) {
                      return _c("el-table-column", {
                        key: index,
                        attrs: {
                          label: !_vm.departmentId
                            ? dept.departmentName
                            : dept.salesmanName,
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  !_vm.departmentId
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.showPrice(
                                                dept.departmentName,
                                                row.achievementUserSourceVoList
                                              )
                                            )
                                        ),
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.showPrice(
                                                dept.salesmanName,
                                                row.achievementUserSourceVoList
                                              )
                                            )
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleClose } },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }