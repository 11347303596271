<template>
  <div class=" oa_main">
    <div class="noa_box">
      <div class="nob_left">
        <div class="nobl_tle">
          <img class="nobll_img" src="https://oss.71go.com/noa0906/noa17.png" alt="">
          <div class="nobll_msg"> 司盟企服 智能办公平台
          </div>
        </div>

        <div class="nobl_user" v-if="!loading">
          <div class="ui_box">
            <img class="user_img" :src="userInfo.avatar" alt="" />
            <img class="user_sex" v-if="userInfo.gender == 2" src="https://oss.71go.com/noa0906/noa11.png" alt="" />
            <img class="user_sex" v-else src="https://oss.71go.com/noa0906/noa10.png" alt="" />
          </div>
          <div class="user_status">
            <img class="us_img" src="https://oss.71go.com/noa0906/noa2.png" alt="出差" :title="imageName" @mouseover="showTooltip" @mouseout="hideTooltip">
            <img class="us_img" src="https://oss.71go.com/noa0906/noa7.png" alt="加班" :title="imageName" @mouseover="showTooltip" @mouseout="hideTooltip">
            <img class="us_img" src="https://oss.71go.com/noa0906/noa12.png" alt="外勤" :title="imageName" @mouseover="showTooltip" @mouseout="hideTooltip">
            <img class="us_img" src="https://oss.71go.com/noa0906/noa23.png" alt="请假" :title="imageName" @mouseover="showTooltip" @mouseout="hideTooltip">
          </div>
          <div class="user_hello">{{ timeMsg }}好！{{ userInfo.username }}</div>
          <div class="user_info">{{ userInfo.entryDay + ' 职级：' + userInfo.rank }}</div>
          <div class="user_honor">
            <img class="us_img" src="https://oss.71go.com/noa0906/noa8.png" alt="进步之星" :title="imageName" @mouseover="showTooltip" @mouseout="hideTooltip">
            <img class="us_img" src="https://oss.71go.com/noa0906/noa15.png" alt="闪耀之星" :title="imageName" @mouseover="showTooltip" @mouseout="hideTooltip">
            <img class="us_img" src="https://oss.71go.com/noa0906/noa29.png" alt="优秀员工" :title="imageName" @mouseover="showTooltip" @mouseout="hideTooltip">
          </div>
          <div class="user_famous">新的一天继续努力 有精神凡事皆有可能</div>
          <div class="fast_entrance">
            <div class="fast_entrance_title">
              <div class="fast_entrance_title_item">快捷入口</div>
            </div>
            <div class="fast_entrance_box">
              <div class="fe_item" v-for="(item, index) in functionList" :key="index" @click.stop="goOtherSys(item.buttonStatus, index,item,1)">
                <img :src="item.icon" alt="">
                <div>{{ item.tabName }}</div>
              </div>
            </div>

          </div>
        </div>

        <el-skeleton v-if="loading" style="width: 240px;margin-top:30px" :loading="loading" animated>
          <template slot="template">
            <el-skeleton-item variant="image" style="width: 240px; height: 240px;" />
            <div style="padding: 14px;">
              <el-skeleton-item variant="p" style="width: 50%" />
              <div style="display: flex; align-items: center; justify-items: space-between;">
                <el-skeleton-item variant="text" style="margin-right: 16px;" />
                <el-skeleton-item variant="text" style="width: 30%;" />
              </div>

            </div>
            <div style="display: flex; align-items: center; justify-content: space-between;flex-wrap:wrap">
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
              <el-skeleton-item variant="text" style="width: 50px; height:50px;margin-top:10px" />
            </div>
          </template>
        </el-skeleton>
      </div>
      <div class="nob_right">
        <div class="nr_top">
          <div class="nrt_msg">{{ userInfo.quote }}</div>
          <div class="nrt_act">
            <!-- <img src="https://oss.71go.com/noa0906/noa38.png" alt=""> -->
            <img class="user_img2" :src="userInfo.avatar" alt="" />
            <el-dropdown>
              <img src="https://oss.71go.com/noa0906/noa37.png" alt="">
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="loginOut()">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </div>
        <div class="nr_bottom">
          <div class="nrb_left">
            <div class="nrbl_top" v-if="!!CmsPopularList">
              <div class="nrbr_bottom">
                <div class="nrbrb_tle">
                  <div>集团通知</div>
                  <div></div>
                </div>
                <div class="nrbrb_box">
                  <div class="nrb_top_box">
                    <img :src="groupNotice[0].url" alt="">
                    <div class="nrbtb_msg" v-if="groupNotice" @click.stop="lookDetailitem(groupNotice[0])">
                      <div class="nrbrb_con_top"><span class="spans">【{{
                          getType(groupNotice[0].type)
                        }}】{{ groupNotice[0].title }}</span>
                        <div style="text-align:right;"> {{ groupNotice[0].releasePerson }} {{
                            groupNotice[0].createTime
                          }}
                        </div>
                      </div>
                      <div class="nrbrb_con1">
                        <div @click.stop="lookDetailitem(item)" v-for="(item, index) in groupNotice.slice(1, 8)" :key="index">
                          <span class="spans">【{{ getType(item.type) }}】{{ item.title }} </span>
                          <span class="" style="font-size: 13px;font-family: PingFang SC;font-weight: 500;color: #999999;">{{
                              item.updatePerson
                            }} {{
                              item.createTime
                            }} </span>
                        </div>
                      </div>
                      <!-- <div>{{ groupNotice[0].createTime }} </div> -->
                    </div>
                  </div>
                  <!-- <div class="nrbrb_con">
                    <div style="display:flex;align-items: center;justify-content: space-between;" @click="lookDetailitem(item)" v-for="(item, index) in groupNotice.slice(1, 5)" :key="index">
                      <span>【{{ getType(item.type) }}】{{ item.title }} </span>
                      <span style="font-size: 13px;font-family: PingFang SC;font-weight: 500;color: #999999;">{{item.updatePerson}}  {{ item.createTime }} </span>
                    </div>
                  </div> -->
                </div>

              </div>

            </div>
            <div class="nrbl_bottom">
              <div class="nrbl_nav">
                <div :class="['nn_item', tabIndex == index ? 'active' : '']" v-for="(item, index) in allTabList" :key="index" @click="changeTab(index, item)">{{ item.tabName }}
                  <div class="flag" v-if="totalCount[index] != 0">{{ totalCount[index] }}</div>
                </div>
                <!-- <div class="slider" :style="{ left: sliderPosition }"></div> -->

              </div>
              <div class="nrbl_box" v-if="messageList">
                <div class="nrblb_item" v-for="(item, index) in messageList" :key="index" @click="goOtherSys(item.buttonStatus, index, item)">
                  <img class="ni_img" src="https://oss.71go.com/noa0906/noa22.png" alt="">

                  <div class="examine">
                    <div>{{ item.title }}
                      <span class="normal">正常</span>
                      <span class="normalIcon" v-if="item.type ==1"></span>
                    </div>
                    <div class="tips"><span v-if="item.realName">提报人：{{
                        item.realName
                      }}</span><span> 提报时间：{{ item.createdTime }}</span>
                    </div>
                  </div>

                  <div class="approval"> 查看详情 ></div>
                </div>

              </div>
              <img v-else class="no_data" src="../../assets/oa/wp/nodata.png" alt="" />

            </div>

          </div>
          <div class="nrb_right">
            <div class="nrbr_top">
              <div class="nbr_calendar" v-loading="rqFlag">
                <img class="add_icon" @click="addItem()" src="https://oss.71go.com/noa0906/noa19.png" alt="" />
                <div class="calendar_top">
                  <div class="ct_years">
                    <span class="year_time">{{ year }}年 {{ month }}月</span>
                  </div>
                  <div class="ct_years">
                    <img class="left_triangle" @click="changeMonth(0)" src="https://oss.71go.com/noa0906/noa34.png" alt="" />
                    <img class="right_triangle" @click="changeMonth(1)" src="https://oss.71go.com/noa0906/noa34.png" alt="" />
                  </div>
                  <div>
                    <el-dropdown placement="bottom" @command="isShowLInk">
                      <img src="https://oss.71go.com/20231031bg/rc2.png" style="cursor:pointer" alt="">
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if="isEditLink" @click.native="editLink()">编辑链接</el-dropdown-item>
                        <el-dropdown-item v-if="!!openLink" @click.native="openSchedule()">本月日程</el-dropdown-item>
                        <el-dropdown-item v-else @click.native="openSchedule()">暂无日程</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
                <div class="calendar_center">
                  <el-carousel @setActiveItem="changeActiveItem" :interval="0" indicator-position="none" ref="carousel" :autoplay="false" :loop="false" height="256px" arrow="always">
                    <el-carousel-item>
                      <div class="cct_list">
                        <div class="cct_item">一</div>
                        <div class="cct_item">二</div>
                        <div class="cct_item">三</div>
                        <div class="cct_item">四</div>
                        <div class="cct_item">五</div>
                        <div class="cct_item">六</div>
                        <div class="cct_item">日</div>
                      </div>
                      <div class="ccb_list">
                        <div :class="[
                          'ccb_item',
                          !day.belong ? ' no_belong' : '',
                          `${year}${9 >= month ? '0' + month : month}${9 >= timeToday ? '0' + timeToday : timeToday
                            }` == day.day &&  day.belong
                            ? 'active'
                            : '',
                        ]" v-for="(day, index) in dayArr" :key="index" @click="changeDateItem(day)">
                          <div class="new_c" v-if="day.belong">{{ day.key }}</div>

                          <div class="dot" v-if="day.list && day.list.length && day.belong > 0"></div>
                        </div>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <div class="calendar_bottom">
                  <el-timeline :reverse="true" v-if="activities && activities.length > 0">
                    <el-timeline-item color="#719cff" v-for="(activity, index) in activities" :key="index" :timestamp="activity.startDate" :hide-timestamp="true">
                      <div class="time_stamp">
                        <span class="tle_leng"> {{ activity.title }}</span>
                        <span class="be_done" v-if="activity.dateType == 1">
                          <span class="shrinkFont">待办</span>
                        </span>
                        <span class="vacation" v-else-if="activity.dateType == 2">
                          <span class="shrinkFont">假期</span>
                        </span>
                        <span class="other" v-else>
                          <span class="shrinkFont">其他事项</span>
                        </span>
                        <img class="close" v-if="activity.dateType == 1" @click="delActivity(activity.id)" src="../../assets/oa/wp/close.png" alt="" />
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                  <div class="no_msg" v-else>暂无事项</div>
                </div>
              </div>

            </div>
            <div class="nrbr_bottom">
              <div class="fast_entrance">
                <div class="fast_entrance_title">
                  <div class="fast_entrance_title_item">系统入口</div>
                </div>
                <div class="fast_entrance_box">
                  <div class="fe_item" v-for="(item, index) in fastList" :key="index" @click="goOtherSys(item.buttonStatus, index,item,2)">
                    <img :src="item.icon" alt="">
                    <div>{{ item.tabName }}</div>
                  </div>
                </div>

              </div>
              <!-- <div class="nrbrb_tle">
                <div>集团通知</div>
                <div></div>
              </div>
              <div class="nrbrb_box">
                <div class="nrb_top_box">
                  <img :src="groupNotice[0].url" alt="">
                  <div class="nrbtb_msg" v-if="groupNotice" @click="lookDetailitem(groupNotice[0])">
                    <div>【{{ getType(groupNotice[0].type) }}】{{ groupNotice[0].title }}</div>
                    <div>{{ groupNotice[0].releaseTime }} {{ groupNotice[0].releasePerson }}</div>
                  </div>
                </div>
                <div class="nrbrb_con">
                  <div @click="lookDetailitem(item)" v-for="(item, index) in groupNotice.slice(1, 3)" :key="index">
                    <span>【{{ getType(item.type) }}】{{ item.title }} </span>
                  </div>
                </div>
              </div> -->

            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="添加我的待办" :visible.sync="dialogVisible" width="500px" style="margin-top: 300px" :before-close="handleClose" center>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="日期">
          <el-date-picker v-model="form.dateStr" style="width: 100%" value-format="timestamp" format="yyyyMMdd" type="date" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="我的待办">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCalendar">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisiblePre" width="1000px" append-to-body>
      <div class="content-box" v-if="htmlCon">
        <div class="head-title">{{ businessJobName }}</div>
        <div class="content" v-if="htmlCon" v-html="htmlCon" @click="imageEnlargement"></div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="openChat" width="1200px" append-to-body>
      <div class="openChat">
        <iframe frameborder="0" :src="`https://nxx.simengqifu.com/#?token=${getToken()}`"></iframe>
        <!-- <iframe frameborder="0" src="https://hunyuan.tencent.com/"></iframe> -->
      </div>
    </el-dialog>
    <el-image-viewer v-if="allPreviewImg" :on-close="closeViewer" :url-list="srcList" :z-index="9999" />
  </div>
</template>

<script>
import {
  addOaCalendar,
  deleteOaSettingTop,
  delOaCalendar,
  getAllTab,
  getListByTab,
  oaCalendar,
  oaSettingTop,
  otherLogin,
  getOaMessageListByType,
  getCmsInternalArticleList,
  getOaMessageCount,
  getOaCmsPopularList,
  getLinkConfig,
  addLinkConfig,
} from "@/api/analysis/oa";
import { getToken } from "@/utils/auth";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    ElImageViewer,
  },
  created() {
    document.title = "oa员工中心";
  },
  mounted() {
    this.getNowTime();
    this.getList();
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    this.gettime();
    this.getNotification();
    this.isShowLInk();
    this.changeBrowserZoom(0.85);
  },
  data() {
    return {
      loading: true,
      getToken: getToken,
      dialogVisiblePre: false,
      htmlCon: "",
      businessJobName: "",

      dialogVisible: false,
      rqFlag: false,
      year: null,
      month: null,
      timeToday: null,
      allTabList: [],
      userInfo: "",
      flagSet: {},
      listSet: {},
      pageNumSet: {},
      totalSet: {},
      loadingSet: {
        loading0: true,
        loading1: true,
        loading2: true,
        loading3: true,
      },
      fastList: [],
      functionList: [],
      activities: [],
      oaCalendar: [],
      dayArr: [],
      form: {
        dateStr: "",
        title: "",
      },
      tableIdList: [],
      openChat: false,
      messageList: [],
      tabIndex: 0,
      groupNotice: [],
      CmsPopularList: [],
      totalCount: {},
      sliderPosition: "0px", // 滑块的位置
      avatar: "/static/img/userImg.2c16f632.png",
      timeMsg: "你",
      imageName: "",
      allPreviewImg: false,
      srcList: [],
      srcListNum: "",
      openLink: "",
      openId: "",
      isEditLink: false,
      tabType: 1,
    };
  },
  methods: {
    checkAndChangeDateItem() {
      // 获取当前日期
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 月份从0开始计算
      const day = String(currentDate.getDate()).padStart(2, "0");

      // 转换为 "YYYYMMDD" 格式
      const formattedDate = `${year}${month}${day}`;
      console.log(formattedDate, "formattedDate");
      // 在 this.dayArr 中查找匹配的 item
      this.dayArr.forEach((item) => {
        if (item.day === formattedDate) {
          console.log(item, "item");
          this.changeDateItem(item); // 调用 changeDateItem 方法
        }
      });
    },
    async isShowLInk() {
      let res = await getLinkConfig();
      if (res.code == 200) {
        this.isEditLink = true;
      } else {
        this.isEditLink = false;
      }
      this.openLink = res.data[0].url;
      this.openId = res.data[0].id;
    },
    changeBrowserZoom(zoomLevel) {
      const devicePixelRatio = window.devicePixelRatio || 1;
      let screenWidth = window.innerWidth;
      let screenHeight = window.innerHeight;
      console.log(devicePixelRatio);
      if (screenHeight > 800) {
        zoomLevel = 1;
      }
      if (devicePixelRatio == 1.5) {
        zoomLevel = 0.6;
      }
      if (devicePixelRatio == 1.25) {
        zoomLevel = 0.75;
      }
      const wholePage = document.documentElement;
      wholePage.style.zoom = zoomLevel;
    },
    loginOut() {
      console.log(2);
      this.$router.push({ path: "/oa/login" });
    },

    goCmsLink(link) {
      let url = `https://www.simengke.com/cms/newsdetail?businessJobKey=${link}`;
      window.open(url);
    },
    changeTab(index, item) {
      this.tabIndex = index;
      const tabWidth = 100 / this.allTabList.length;
      console.log("tabWidth-----------", tabWidth);
      console.log("item-----------", item);
      this.sliderPosition = `${tabWidth * index + 6}%`;
      getListByTab({
        tabId: item.id,
        pageNum: 1,
        pageSize: 20,
        type: item.tabType,
      }).then((res) => {
        this.messageList = res.rows;
      });
    },
    getListByTab(data, tableIndex) {
      getListByTab(data).then((res) => {
        if (res.code == 200) {
          this.loadingSet[`loading${tableIndex}`] = false;
          this.listSet[`list${tableIndex}`] = res.rows;
          console.log(
            "this.listSet[`list${tableIndex}`]",
            this.listSet[`list${tableIndex}`]
          );
        }
      });
    },
    oaSettingTop(data, tableIndex) {
      this.loadingSet[`loading${tableIndex}`] = true;
      oaSettingTop(data).then((res) => {
        console.log("新增置顶res", res);
        if (res.code == 200) {
          let paramsData = {
            tabId: data.tableId,
            pageNum: 1,
            pageSize: 20,
            type: this.tabType,
          };
          this.getListByTab(paramsData, tableIndex);
        }
      });
    },
    deleteOaSettingTop(data, tableIndex) {
      this.loadingSet[`loading${tableIndex}`] = true;
      deleteOaSettingTop(data).then((res) => {
        console.log("取消置顶res", res);
        if (res.code == 200) {
          let data = {
            tabId: this.tableIdList[tableIndex],
            pageNum: 1,
            pageSize: 20,
          };
          this.getListByTab(data, tableIndex);
        }
        // this.loadingSet[`loading${tableIndex}`]=false
      });
    },
    listCheck(list, tableIndex, item) {
      console.log("置顶list", list);
      console.log("置顶", item);
      let data = {
        buttonStatus: item.buttonStatus,
        buttonstatusname: item.buttonStatusName,
        title: item.title,
        tableId: this.tableIdList[tableIndex],
      };
      this.oaSettingTop(data, tableIndex);
    },
    listNoCheck(list, tableIndex, item) {
      console.log("取消置顶list", list);
      console.log("取消置顶", item);
      let data = {
        idList: item.id,
      };
      this.deleteOaSettingTop(data, tableIndex);
    },
    // 数组选中状态判断方法
    indexCheck(list, index, fields, bol) {
      let newItem = {};
      list.forEach((item) => {
        item[fields] = false;
      });
      newItem = list[index];
      newItem[fields] = bol;
      this.$set(list, index, newItem);
    },
    mouseenter(index) {
      this.indexCheck(this.fastList, index, "check", true);
    },
    mouseleave(index) {
      this.indexCheck(this.fastList, index, "check", false);
    },
    allTabListCheck(index) {
      this.indexCheck(this.allTabList, index, "checks", true);
    },
    handleScrollOne(event) {
      let bottom =
        event.target.scrollHeight - event.target.scrollTop ===
        event.target.clientHeight;
      if (bottom) {
        // 触底lo
      } else {
        // 未触底
      }
    },
    async getList() {
      let res = await getAllTab();
      let res2 = await getOaCmsPopularList();
      let res1 = await getOaMessageCount();

      if (res1.code == 200) {
        console.log(Object.values(res1.data));
        this.totalCount = Object.values(res1.data);
      }
      if (res2.code == 200) {
        console.log(res2);
        this.CmsPopularList = res2.data;
      }
      if (res.code == 200) {
        this.allTabList = res.data.allTabList.filter(
          (item) => item.id != 18 && item.id != 33
        );
        this.changeTab(0, this.allTabList[0]);
        this.allTabList[0].checks = true;
        this.userInfo = res.data;
        res.data.allTabList.forEach((item, index) => {
          if (item.id == "18") {
            this.fastList = item.secondTabList;
            this.loading = false;
          }
          if (item.id == "33") {
            this.functionList = item.secondTabList;
            this.loading = false;
          }
        });
      }
    },
    // 集团通知点击查看更多
    clickMore() {},
    // 集团通知详情（放假通知,早回复盘）
    async getNotification() {
      let res = await getCmsInternalArticleList({ pageNum: 1, pageSize: 10 });
      console.log("集团通知res", res);
      this.groupNotice = res.rows;
    },
    lookDetailitem(item) {
      console.log("item", item);
      this.htmlCon = item.htmlText;
      this.srcList = this.fn(this.htmlCon);
      this.businessJobName = item.title;
      this.dialogVisiblePre = true;
    },
    fn(str) {
      var data = [];
      str.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
        function (match, capture) {
          data.push(capture);
        }
      );
      console.log("src路径", data);
      return data;
    },
    changeSecondTab(type, index) {
      this.tableIdList[type] = index;
      console.log(`this.tableIdList[${type}]`, this.tableIdList);
      this.$refs[`Scroll${type}`].scrollTop = 0;
      this.flagSet[`flag${type}`] = index;
      this.loadingSet[`loading${type}`] = true;
      this.pageNumSet[`pageNum${type}`] = 1;
      getListByTab({
        tabId: index,
        pageNum: 1,
        pageSize: 20,
        type: this.tabType,
      }).then((res) => {
        if (res.code == 200) {
          this.listSet[`list${type}`] = res.rows;
          this.loadingSet[`loading${type}`] = false;
          this.totalSet[`total${type}`] = res.total;
        }
      });
    },
    getModifiedUrl(oldUrl, newUrl, buttonStatus) {
      if (newUrl) {
        if (buttonStatus == 24 || buttonStatus == 36) {
          const buildNewUrl = newUrl.replace(/&/g, "%26");
          console.log("buildNewUrl", buildNewUrl);
          console.log(
            "-----1111",
            oldUrl.replace(/redirect=([^&]*)/, `redirect=${buildNewUrl}`)
          );
          return oldUrl.replace(/redirect=([^&]*)/, `redirect=${buildNewUrl}`);
        } else {
          const buildNewUrl = newUrl.replace(/&/g, "%26");
          console.log("buildNewUrl", buildNewUrl);
          console.log(
            "-----222",
            oldUrl.replace(/path=([^&]*)/, `path=${buildNewUrl}`)
          );
          return oldUrl.replace(/path=([^&]*)/, `path=${buildNewUrl}`);
        }
      } else {
        return oldUrl;
      }
    },
    goOtherSys(buttonStatus, index, item, type) {
      console.log('item------------',item);
      if(item.id===91){
        window.open('https://www.coze.cn/store/agent/7432205289363013667?bid=6ea3fm9k0400q')
      }
      if (!buttonStatus) {
        return false;
      }
    
      if (
        buttonStatus == "21" ||
        buttonStatus == "9" ||
        buttonStatus == "24" ||
        buttonStatus == "36"
      ) {
        otherLogin({ buttonStatus: buttonStatus }).then((res) => {
          if (res.code == 200) {
            // console.log('---------',this.getModifiedUrl(res.data, item.url));
            window.open(
              this.getModifiedUrl(
                res.data,
                item && item.url ? item.url : null,
                buttonStatus
              )
            );
          }
        });
        return false;
      }
      console.log(index);
      console.log("item", item);
      if (
        item.tabName != "有道翻译" &&
        item.tabName != "智慧文小星" &&
        item.tabName != "智能K小星" &&
        (index || index == 0)
      ) {
        if (type == 1) {
          this.indexCheck(this.functionList, index, "checks", true);
        } else {
          this.indexCheck(this.fastList, index, "check", true);
        }
      }
      // 跳转chatGPT弹窗
      if (item.tabName == "智能牛小星") {
        this.openChat = true;
        // window.open('https://hunyuan.tencent.com/')
      } else if (item.tabName == "有道翻译") {
        window.open("https://www.youdao.com/");
      } else if (item.tabName == "智慧文小星") {
        window.open("https://yiyan.baidu.com/");
      } else if (item.tabName == "智能K小星") {
        window.open("https://kimi.moonshot.cn/");
      } else if (item.tabName == "业绩看板") {
        if (process.env.NODE_ENV !== "development") {
          window.open(
            "https://cloud.simengqifu.com/oa/data?salesmanId=" +
              this.userInfo.userId
          );
        } else {
          window.open(
            "https://cloud.simengqifu.cn/oa/data?salesmanId=" +
              this.userInfo.userId
          );
        }
      } else {
        otherLogin({ buttonStatus: buttonStatus }).then((res) => {
          if (res.code == 200) {
            let system = navigator.userAgent;
            let isAndroid =
              system.indexOf("Android") > -1 || system.indexOf("Adr") > -1; // android终端
            let isiOS = !!system.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
              //android终端
              console.log("我是安卓");
              window.open(res.data);
            } else if (isiOS) {
              //ios终端
              console.log("我是ios");
              window.location.href = res.data;
            } else {
              // 其他设备
              console.log("其他设备");
              window.open(res.data);
            }
          }
        });
      }
    },
    moreList(type, flag) {
      this.pageNumSet[`pageNum${type}`]++;
      getListByTab({
        tabId: flag,
        pageNum: this.pageNumSet[`pageNum${type}`],
        pageSize: 20,
        type: this.tabType,
      }).then((res) => {
        if (res.code == 200) {
          this.listSet[`list${type}`] = this.listSet[`list${type}`].concat(
            res.rows
          );
        }
      });
    },
    async getEveryDay(date, month, year, ischange) {
      this.rqFlag = true;

      date.setMonth(month + 1);
      date.setDate(0);
      //获取月份的某一天
      let day = date.getDate();
      let data = [];
      for (let i = 1; i <= day; i++) {
        date.setDate(i);

        data.push({
          day: `${year}${month + 1 > 9 ? month + 1 : "0" + (month + 1)}${
            i > 9 ? i : "0" + i
          }`,
          month: month + 1,
          key: i,
          table_one: i,
          //选中月份的每一天和当天是星期几
          table_two: [7, 1, 2, 3, 4, 5, 6][date.getDay()],
          belong: true,
        });
      }
      let filterGoods = [[], [], [], [], [], [], []];
      let filterIndex = 0;
      let findex = 0;
      console.log(data, "ddcobj");
      data.forEach((item, index) => {
        if (index == 0) {
          let copyarr = [];
          for (var dayindex = 1; dayindex <= 7; dayindex++) {
            if (dayindex >= item.table_two) {
              console.log("进了这里");
              let cobj = {
                day: this.dealTime(dayindex, item.day, "add", findex),
                table_two: dayindex,
                belong: true,
                month: item.month,
                key: parseInt(
                  this.dealTime(dayindex, item.day, "add", findex).slice(
                    this.dealTime(dayindex, item.day).length - 2,
                    this.dealTime(dayindex, item.day).length
                  )
                ),
              };
              console.log("cobj", cobj);
              copyarr.push(cobj);
              findex++;
            } else {
              console.log("没进了这里");
              copyarr.push({
                day: this.dealTime(dayindex, item.day),
                table_two: dayindex,
                belong: false,
                month: item.month != 1 ? item.month - 1 : 12,
                key: this.dealTime(dayindex, item.day).slice(
                  this.dealTime(dayindex, item.day).length - 2,
                  this.dealTime(dayindex, item.day).length
                ),
              });
            }
          }

          filterGoods[filterIndex] = copyarr;
        } else if (index == data.length - 1 && item.table_two !== 7) {
          let copyarr = [];
          for (var dayindex = 1; dayindex <= 7; dayindex++) {
            if (dayindex > item.table_two) {
              copyarr.push({
                day: this.dealTime(dayindex, item.day),
                table_two: dayindex,
                belong: false,
                month: item.month != 12 ? item.month + 1 : 1,
                key: this.dealTime(dayindex, item.day).slice(
                  this.dealTime(dayindex, item.day).length - 2,
                  this.dealTime(dayindex, item.day).length
                ),
              });
            } else {
              copyarr.push({
                day: this.dealTime(dayindex, item.day, "reduce"),
                table_two: dayindex,
                belong: true,
                month: item.month != 1 ? item.month - 1 : 12,
                key: parseInt(
                  this.dealTime(dayindex, item.day, "reduce").slice(
                    this.dealTime(dayindex, item.day).length - 2,
                    this.dealTime(dayindex, item.day).length
                  )
                ),
              });
            }
          }

          filterGoods[filterGoods.length - 1] = copyarr;
        }
        if (item.table_two == 1) {
          filterIndex++;
        }

        if (filterGoods[filterIndex].length < 7) {
          typeof filterGoods[filterIndex] == "undefined"
            ? ""
            : filterGoods[filterIndex].push(item);
        }
      });

      // filterGoods.splice(1, 1);
      // filterGoods.splice(4, 1);
      let newArrCopy = [];
      for (let caindex = 0; caindex < filterGoods.length; caindex++) {
        if (filterGoods[caindex].length == 0) {
          continue;
        }
        if (caindex == 0 || caindex == filterGoods.length) {
          newArrCopy.push(filterGoods[caindex]);
          continue;
        }

        if (
          filterGoods[caindex].length < 7 &&
          caindex != filterGoods.length - 1
        ) {
        } else {
          newArrCopy.push(filterGoods[caindex]);
        }
      }

      if (newArrCopy[0][0].day == newArrCopy[1][0].day) {
        newArrCopy.splice(0, 1);
      }
      filterGoods = newArrCopy;

      let startDate = `${filterGoods[0][0].day.substring(
        0,
        4
      )}-${filterGoods[0][0].day.substring(
        4,
        6
      )}-${filterGoods[0][0].day.substring(6, 8)}`;
      let endDate = `${filterGoods[filterGoods.length - 1][
        filterGoods[filterGoods.length - 1].length - 1
      ].day.substring(0, 4)}-${filterGoods[filterGoods.length - 1][
        filterGoods[filterGoods.length - 1].length - 1
      ].day.substring(4, 6)}-${filterGoods[filterGoods.length - 1][
        filterGoods[filterGoods.length - 1].length - 1
      ].day.substring(6, 8)}`;
      let oaCalendarArr = await this.getoaCalendar(startDate, endDate);

      filterGoods.forEach((fgitem, index) => {
        fgitem.forEach((fm) => {
          if (
            fm.day ==
            `${this.year}${9 >= this.month ? "0" + this.month : this.month}${
              9 >= this.timeToday ? "0" + this.timeToday : this.timeToday
            }`
          ) {
            let that = this;
            setTimeout(function () {
              that.changeActiveItem(index);
              that.changeDateItem(fm);
              that.rqFlag = false;
            }, 500);
          }
          fm.list = oaCalendarArr[0][`${fm.day}`];
        });
      });
      this.rqFlag = false;
      let edaArr = filterGoods[filterGoods.length - 1];
      const count = edaArr.reduce((acc, obj) => {
        if (obj.belong) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
      edaArr.forEach((act, index) => {
        act.day = edaArr[edaArr.length - 1].day - (count - (index + 1)) + "";
        act.key = edaArr[edaArr.length - 1].key - (count - (index + 1));
      });

      console.log("filterGoods", filterGoods[filterGoods.length - 1]);
      this.dayArr = filterGoods.flat();
      this.checkAndChangeDateItem();
    },
    dealTime(dayNum, dat, type = "", i) {
      if (type == "add") {
        let fday = dat.substring(6, 8);
        let day = Number(fday) + i;
        return dat.substring(0, 6) + "" + (day > 9 ? day : "0" + day);
      } else if (type == "reduce") {
        let fday = dat.substring(6, 8);
        let day = Number(fday);
        return dat.substring(0, 6) + "" + (day > 9 ? day : "0" + day);
      } else {
        return dat;
      }
    },
    changeMonth(flag) {
      if (this.rqFlag) {
        return;
      }
      this.rqFlag = true;
      switch (flag) {
        case 0:
          if (this.month == 1) {
            this.month = 12;
            this.year = parseInt(this.year) - 1;
          } else {
            this.month = this.month - 1;
          }

          break;
        case 1:
          if (this.month == 12) {
            this.month = 1;
            this.year = parseInt(this.year) + 1;
          } else {
            this.month = this.month + 1;
          }
          break;
      }
      this.gettime(`${this.year}-${this.month}-01`, true);
      // this.changeActiveItem(0);
    },
    gettime(row, ischange) {
      let date = row ? new Date(row) : new Date(),
        month = date.getMonth(),
        year = date.getFullYear(),
        timeToday = date.getDate();
      this.timeToday = timeToday;
      this.year = year;
      this.month = month + 1;
      this.getEveryDay(date, month, year, ischange);
    },
    changeActiveItem(index) {
      if (this.$refs.carousel) {
        this.$refs.carousel.setActiveItem(index);
      }
    },
    async getoaCalendar(startDate, endDate) {
      let res = await oaCalendar({ startDate: startDate, endDate: endDate });
      if (res.code == 200) {
        return res.data.list;
      }
    },
    contrastArr(arr) {
      if (arr[arr.length - 2][0].month > arr[arr.length - 1][0].month) {
        arr.splice(arr.length - 1, 1);
      }
      return arr;
    },
    changeDateItem(day) {
      if (!day.belong) {
        return false;
      }
      console.log(day);
      this.timeToday = parseInt(day.day.substring(6, 8));
      this.month = parseInt(day.day.substring(4, 6));
      this.year = day.day.substring(0, 4);

      // this.timeToday = day.day
      this.activities = day.list;
    },
    addItem() {
      this.dialogVisible = true;
      this.form.dateStr = `${this.year}-${
        9 >= this.month ? "0" + this.month : this.month
      }-${9 >= this.timeToday ? "0" + this.timeToday : this.timeToday}`;
      console.log(9 >= this.timeToday ? "0" + this.timeToday : this.timeToday);
      var date = new Date(this.form.dateStr); //时间对象
      this.form.dateStr = date.getTime(); //转换成时间戳
    },
    addCalendar() {
      if (!this.form.title) {
        this.$message.error("请输入你的待办");
        return false;
      }

      var date = new Date(this.form.dateStr);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      let datemsg = Y + M + (9 >= D ? "0" + D : D);
      this.form.dateStr = datemsg.split("-").join("").trim();
      // console.log(this.form.dateStr);

      addOaCalendar(this.form).then((res) => {
        if (res.code == 200) {
          this.gettime(`${this.year}-${this.month}-${this.timeToday}`);
          this.dialogVisible = false;
          this.form.title = "";
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    delActivity(id) {
      this.$confirm("是否删除待办?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        modal: false,
      })
        .then(() => {
          delOaCalendar({ id: id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              this.gettime(`${this.year}-${this.month}-${this.timeToday}`);
            }
          });
        })
        .catch(() => {});
    },
    getNowTime() {
      var now = new Date();
      // 获取当前小时数
      var hour = now.getHours();
      let msg;
      // 判断是早上、中午还是晚上
      if (hour >= 6 && hour < 12) {
        msg = "早上";
      } else if (hour >= 12 && hour < 18) {
        msg = "中午";
      } else {
        msg = "晚上";
      }
      this.timeMsg = msg;
    },
    async getOaMessageList(type) {
      let res = await getOaMessageListByType({ type: type });
      console.log(res);
      this.messageList = res.data;
    },
    showTooltip(event) {
      this.imageName = event.target.alt;
    },
    hideTooltip() {
      this.imageName = "";
    },
    getType(type) {
      let txt = "";
      switch (type) {
        case "0":
          txt = "重要通知";
          break;
        case "1":
          txt = "产品解读";
          break;
        case "2":
          txt = "政策解读";
          break;
        case "3":
          txt = "功能上新";
          break;

        default:
          break;
      }
      return txt;
    },
    imageEnlargement(e) {
      if (e.target.nodeName == "IMG") {
        //判断点击富文本内容为img图片
        console.log("点击图片", e.target.currentSrc);
        this.allPreviewImg = true;
        document.body.style.overflow = "hidden";
        this.srcListNum = e.target.currentSrc;
        const tempImgList = this.srcList; // 所有图片地址
        this.srcList.forEach((item, index) => {
          console.log("item", item);
          if (item == this.srcListNum) {
            const start = tempImgList.splice(index, 1);
            console.log("start", start);
            console.log("tempImgList", tempImgList);
            this.srcList = start.concat(tempImgList);
            console.log("tempImgList", this.srcList);
          }
        });
      } else {
        console.log("点击内容不为img");
      }
    },
    closeViewer() {
      this.allPreviewImg = false;
      document.body.style.overflow = "auto";
    },
    editLink() {
      this.$prompt("请输入链接", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
      })
        .then(async ({ value }) => {
          if (!value) {
            value = "";
          }
          await addLinkConfig({ url: value, id: this.openId }).then((res) => {
            console.log(res);
            this.isShowLInk();
          });
          this.$message({
            type: "success",
            message: "输入成功",
          });
        })
        .catch(() => {});
    },
    openSchedule() {
      console.log("ddd");
      if (!this.openLink) {
        this.$message({
          type: "error",
          message: "暂无日程",
        });
      } else {
        window.open(this.openLink, "_blank");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.openChat {
  iframe {
    width: 1140px;
    height: 760px;
  }
}

.content-box {
  padding-top: 5px;
  width: 900px;
  margin: 20px auto;
  padding-bottom: 80px;
  border-radius: 20px;
  box-shadow: 2px 12px 30px 9px #e8e8e8;
  z-index: 111;
  filter: opacity(100%);
  position: relative;

  .boxs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .head-title {
    font-size: 32px;
    line-height: 40px;
    color: #333333;
    font-weight: 700;
    margin-top: 40px;
    padding-left: 50px;
    text-align: center;
  }

  .content {
    width: 800px;
    min-height: 300px;
    margin: 0 auto;
    line-height: 30px;
    color: #333333;
    font-family: Microsoft YaHei;

    h1 {
      line-height: 2rem;
      margin-bottom: 20px;
    }

    h2 {
      line-height: 2rem;
      margin-bottom: 20px;
    }

    h3 {
      line-height: 2rem;
      margin-bottom: 20px;
    }

    ::v-deep p {
      width: 100% !important;

      img {
        max-width: 100% !important;
      }
    }

    img {
      max-width: 800px !important;
    }
  }
}

.main_box {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  color: #4e78fe;
  font-weight: bold;
  font-family: Source Han Sans SC-Regular, Source Han Sans SC;
  overflow: auto;
  box-sizing: border-box;
  // min-width: 1800px;

  .oa_header {
    height: 110px;
    width: 100%;
    background: url("../../assets/oa/new/heardbgc.png") no-repeat;
    background-size: 100% 100%;
    padding: 10px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .oa_header_left {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-family: Source Han Sans CN VF;
      font-weight: 400;
      color: #ffffff;
      white-space: nowrap;

      img {
        width: 300px;
        height: 70px;
        object-fit: contain;
      }

      .user_img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 4px solid #b6b2fc;
        margin-right: 10px;
      }

      .user_box {
        .user_name {
          // width: 280px;
          font-size: 22px;
          font-family: Source Han Sans CN VF;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 8px;
          white-space: nowrap;
          overflow: hidden;
        }

        .user_date {
          font-size: 19px;
          font-family: Source Han Sans CN VF;
          font-weight: 400;
          color: #c2d4fb;
        }
      }
    }

    .oa_header_right {
      display: flex;
    }
  }

  .oa_person_box {
    background-color: #fff;
    background: Linear-gradient(to bottom, #fff, #f2f2f2);

    .oa_person_main {
      background-image: url(../../assets/oa/wp/img10.png);
      background-size: 100% 100%;
      height: 200px;
      // border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-weight: 500;

      .user_img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 4px solid #b6b2fc;
      }

      .user_name {
        color: #fff;
        margin: 10px 0;
      }

      .user_date {
        color: #aab8fd;
        font-size: 15px;
      }
    }
  }

  .news_box {
    width: 100%;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    // height: 70vh;
    font-family: Source Han Sans SC-Regular, Source Han Sans SC;

    .nb_left {
      // flex: 3;
      width: 70%;
      // min-width: ;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // margin-right: 20px;
      .nbr_top {
        width: 100%;
        // background: linear-gradient(
        //   133deg,
        //   #ffa370 0%,
        //   #6d9eff 0%,
        //   #be8aff 100%
        // );
        border-radius: 20px;
        margin-bottom: 15px;
        position: relative;

        & ::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 2px;
          /*高宽分别对应横竖滚动条的尺寸*/
          height: 5px;
        }

        & ::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 3px #fab590;
          background: #fab590;
        }

        & ::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          // -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          background: #f2f2f3;
        }

        .fast_tle {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #232323;
          margin: 0 0 10px 0;
        }

        .fast_box {
          width: 100%;
          height: 170px;
          border-radius: 20px 20px 20px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow-x: scroll;
          overflow-y: hidden;

          .fast_item {
            width: 100%;
            // width: 146px;
            height: 152px;
            background: #ffffff;
            border: 3px solid #e6efff;
            box-shadow: 1px 0px 3px 0px rgba(246, 249, 255, 0.38),
              -1px 0px 3px 0px #f6f9ff;
            border-radius: 22px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #232323;
            cursor: pointer;
            margin-right: 12px;
            padding: 10pxc;

            .fast_icon {
              width: 66px;
              height: 62px;
              object-fit: contain;
              margin-bottom: 20px;
            }

            span {
              white-space: nowrap;
            }
          }

          .fast-check {
            border: 3px solid #ff8400;
          }
        }
      }

      .nbl_top {
        width: 100%;
        // flex: 1;
        display: flex;
        justify-content: space-between;
        min-height: 306px;

        // 第一个盒子
        .nblt_left {
          // flex: 1;
          width: 49%;
          max-width: 49%;
          background: #fff;
          border-radius: 20px;
          // display: flex;
          // width: 590px;
          // max-height: 283px;
          overflow: hidden;

          & ::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 5px;
          }

          & ::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 3px #fab590;
            background: #fab590;
          }

          & ::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #ffffff;
          }

          .nblt_right-heard {
            width: 100%;
            height: 60px;
            background: linear-gradient(0deg, #007bf6 0%, #2fbdff 100%);
            border-radius: 22px 22px 0px 0px;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 60px;
            padding-left: 35px;
            display: flex;

            .com_title {
              color: #ffffff;
              margin-right: 100px;
              cursor: pointer;
            }

            .title-active {
              border-bottom: 3px solid #ff8400;
              // transform: translateY(3px);
            }
          }

          .nbltl_left {
            width: 100%;
            border-right: 1px solid #dbdbdb;

            .com_nav {
              color: #ff7c32;
              display: flex;
              // flex-wrap: wrap;
              align-items: center;
              box-sizing: border-box;
              height: 90px;
              padding: 10px 40px;
              font-weight: 400;
              overflow-x: auto;

              .cn_item {
                width: 110px;
                height: 44px;
                line-height: 44px;
                cursor: pointer;
                border: 1px solid #ff6c00;
                border-radius: 8px;
                position: relative;
                text-align: center;
                margin: 0 20px 10px 0;
                box-sizing: border-box;
                padding: 0 12px;
                white-space: nowrap;

                .redFlag {
                  position: absolute;
                  top: -3px;
                  right: 0;
                  width: 20px;
                  height: 20px;
                  line-height: 20px;
                  background: red;
                  color: #fff;
                  border-radius: 50%;
                  font-size: 12px;
                  text-align: center;
                }
              }

              .active {
                background: #ff7c32;
                color: #fff;
                font-size: 17px;
                background: linear-gradient(90deg, #ff3000 0%, #ff9600 94%);
                border-radius: 8px;
              }
            }

            .com_navs {
              display: flex;
              justify-content: flex-start;
            }
          }

          .nbltl_right {
            flex: 9;
            color: #232323;
            max-height: 300px;
            overflow-y: auto;
            position: relative;
            //修改loading加载时的颜色

            .com_list {
              .comi_item {
                padding: 10px 20px;

                .comi_items {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 16px;
                  padding-bottom: 10px;
                  border-bottom: 1px solid #d5d5d5;
                  font-family: PingFang SC;

                  .comii_left {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 320px;
                  }

                  .comii_right {
                    display: flex;
                    align-items: center;

                    .comii-check {
                      width: 25px;
                      height: 25px;
                      margin-right: 10px;
                      cursor: pointer;
                      vertical-align: middle;
                    }

                    .comii-btn {
                      color: #ffffff;
                      background: #ff5500;
                      border-radius: 6px;
                      cursor: pointer;
                      transition: linear 0.2s all;
                      // width: 90px;
                      height: 32px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: 15px;
                      font-weight: 400;
                      padding: 0 10px;
                      white-space: nowrap;
                    }

                    .comii-btn:hover {
                      color: #fff;
                      background: #ff5500;
                      transform: scale(1.1);
                    }
                  }
                }
              }

              .more_btn {
                color: #ff5500;
                border: 1px solid #ff5500;
                width: 110px;
                padding: 3px;
                text-align: center;
                border-radius: 20px;
                margin: 0 auto;
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        // 第二个盒子
        .nblt_right {
          // flex: 1;
          width: 49%;
          max-width: 49%;
          height: 482px;
          background: #fff;
          border-radius: 20px;
          // width: 590px;

          & ::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 5px;
          }

          & ::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 3px #70a1ff;
            background: #70a1ff;
          }

          & ::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #ffffff;
          }

          .nblt_right-heard {
            width: 100%;
            height: 60px;
            background: linear-gradient(0deg, #007bf6 0%, #2fbdff 100%);
            border-radius: 22px 22px 0px 0px;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 60px;
            padding-left: 35px;
          }

          .nbltr_left {
            width: 100%;

            .com_title {
              color: #444444;
              text-align: center;
              display: flex;

              align-items: center;

              img {
                margin-right: 4px;
              }
            }

            .com_nav {
              width: 100%;
              color: #ff6c00;
              display: flex;
              // flex-wrap: wrap;
              align-items: center;
              box-sizing: border-box;
              height: 90px;
              padding: 10px 40px;
              font-weight: 400;
              overflow-x: auto;

              .cn_item {
                width: 110px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                cursor: pointer;
                transition: linear 0.2s all;
                border-radius: 8px;
                position: relative;
                border: 1px solid #ff6c00;
                margin: 0 20px 10px 0;
                box-sizing: border-box;
                padding: 0 12px;
                white-space: nowrap;

                .redFlag {
                  position: absolute;
                  top: -3px;
                  right: 0;
                  width: 20px;
                  height: 20px;
                  line-height: 20px;
                  background: red;
                  color: #fff;
                  border-radius: 50%;
                  font-size: 12px;
                  text-align: center;
                }
              }

              .cn_item:last-child {
                margin-right: 0;
              }

              .active {
                color: #fff;
                // padding: 0 8px;
                border-radius: 15px;
                font-size: 17px;
                background: linear-gradient(90deg, #ff3000 0%, #ff9600 94%);
                border-radius: 8px;
              }
            }
          }

          .nbltr_right {
            flex: 9;
            color: #232323;
            max-height: 300px;
            overflow-y: auto;
            position: relative;

            .com_list {
              .comi_item {
                padding: 10px 20px;

                .comi_items {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 16px;
                  box-sizing: border-box;
                  padding-bottom: 10px;
                  border-bottom: 1px solid #d5d5d5;
                  font-family: PingFang SC;

                  .comii_left {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 320px;
                  }

                  .comii_right {
                    display: flex;
                    align-items: center;

                    .comii-check {
                      width: 25px;
                      height: 25px;
                      margin-right: 10px;
                      cursor: pointer;
                      vertical-align: middle;
                    }

                    .comii-btn {
                      color: #ffffff;
                      background: #149cfb;
                      border-radius: 6px;
                      cursor: pointer;
                      transition: linear 0.2s all;
                      width: 90px;
                      height: 32px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: 15px;
                      font-weight: 400;
                    }

                    .comii-btn:hover {
                      color: #fff;
                      transform: scale(1.1);
                      background: #149cfb;
                    }
                  }
                }
              }

              .more_btn {
                color: #149cfb;
                border: 1px solid #149cfb;
                width: 110px;
                padding: 3px;
                text-align: center;
                border-radius: 20px;
                margin: 0 auto;
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .nbl_bottom {
        margin-top: 15px;
        flex: 1;
        display: flex;
        min-height: 306px;

        // 第三个盒子
        .nblb_left {
          flex: 6;
          background: #fff;
          border-radius: 20px;
          margin-right: 40px;
          padding: 10px;
          display: flex;

          & ::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 1px;
          }

          & ::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 3px #a66eeb;
            background: #a66eeb;
          }

          & ::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #ffffff;
          }

          .nblbl_left {
            min-width: 120px;
            max-width: 120px;
            flex: 3;
            border-right: 1px solid #dbdbdb;

            .com_title {
              color: #444444;
              text-align: center;
              display: flex;

              align-items: center;

              img {
                margin-right: 4px;
              }
            }

            .com_nav {
              color: #7722e2;
              display: flex;
              justify-content: space-around;
              flex-wrap: wrap;
              flex-direction: column;
              min-height: 230px;
              justify-content: space-around;
              font-weight: 400;

              // padding: 0 10px;
              .cn_item {
                width: 100%;
                text-align: center;
                cursor: pointer;
                transition: linear 0.2s all;
                padding: 6px 8px;
                border-radius: 15px;
                position: relative;

                .redFlag {
                  position: absolute;
                  top: -3px;
                  right: 0;
                  width: 20px;
                  height: 20px;
                  line-height: 20px;
                  background: red;
                  color: #fff;
                  border-radius: 50%;
                  font-size: 12px;
                  text-align: center;
                }
              }

              .active {
                color: #fff;
                padding: 6px 8px;
                border-radius: 15px;
                font-size: 17px;
                transform: scale(1.05);
                background: linear-gradient(90deg, #7722e2 0%, #a871ec 100%);
                box-shadow: 0px 6px 10px 1px rgba(119, 34, 226, 0.2);
              }
            }
          }

          .nblbl_right {
            flex: 10;
            color: #585858;
            max-height: 280px;
            overflow-y: auto;
            position: relative;

            .com_list {
              .comi_item {
                display: flex;
                padding: 10px 20px;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;

                .comii_right {
                  color: #7722e2;
                  border: 1px solid #7722e2;
                  border-radius: 20px;
                  cursor: pointer;
                  transition: linear 0.2s all;
                  width: 90px;
                  height: 26px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px;
                  font-weight: 400;
                }

                .comii_left {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 250px;
                }

                .comii_right:hover {
                  color: #fff;
                  transform: scale(1.1);
                  background: #7722e2;
                  border-radius: 20px;
                }
              }

              .more_btn {
                color: #7722e2;
                border: 1px solid #7722e2;
                width: 110px;
                padding: 3px;
                text-align: center;
                border-radius: 20px;
                margin: 0 auto;
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }

        // 第四个盒子
        .nblb_right {
          flex: 7;
          background: #fff;
          border-radius: 20px;
          padding: 10px;

          & ::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 1px;
          }

          & ::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 3px #ff80ae;
            background: #ff80ae;
          }

          & ::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: #ffffff;
          }

          .nblbr_top {
            height: 60px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #dbdbdb;
            transform: translateY(-15px);

            .nblbrt_left {
              width: 119px;
              color: #444444;
              text-align: center;
              display: flex;
              align-items: center;

              img {
                margin-right: 4px;
              }
            }

            .nblbrt_right {
              min-width: 470px;
              flex: 1;

              .com_nav {
                color: #ff488b;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                font-weight: 400;

                // padding: 0 10px;
                .cn_item {
                  width: 100%;
                  text-align: center;
                  cursor: pointer;
                  transition: linear 0.2s all;
                  padding: 6px 2px;
                  border-radius: 15px;
                  position: relative;

                  .redFlag {
                    position: absolute;
                    top: -3px;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    background: red;
                    color: #fff;
                    border-radius: 50%;
                    font-size: 12px;
                    text-align: center;
                  }
                }

                .active {
                  color: #fff;
                  padding: 6px 8px;
                  font-size: 17px;
                  transform: scale(1.05) translateY(15px);
                  background: linear-gradient(270deg, #ff83b0 0%, #ff488b 100%);
                  box-shadow: 0px 3px 10px 1px rgba(255, 72, 139, 0.2);
                }
              }
            }
          }

          .nblbr_bottom {
            max-height: 200px;
            overflow-y: auto;
            text-align: center;

            .com_list {
              color: #585858;

              .comi_item {
                display: flex;
                padding: 10px 20px;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;

                .comii_point {
                  width: 8px;
                  height: 8px;
                  background: #4484ff;
                  border-radius: 50%;
                  opacity: 1;
                  margin-right: 10px;
                }

                .comii_right {
                  color: #ff83b0;
                  border: 1px solid #ff83b0;
                  border-radius: 20px;
                  cursor: pointer;
                  transition: linear 0.2s all;
                  width: 90px;
                  height: 26px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 14px;
                  font-weight: 400;
                }

                .comii_left {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 500px;
                  flex: 1;
                }

                .comii_right:hover {
                  color: #fff;
                  transform: scale(1.1);
                  background: linear-gradient(270deg, #ff83b0 0%, #ff488b 100%);
                  border-radius: 20px;
                }
              }

              .more_btn {
                color: #ff83b0;
                border: 1px solid #ff83b0;
                width: 110px;
                padding: 3px;
                text-align: center;
                border-radius: 20px;
                margin: 0 auto;
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .nb_right {
      // flex: 2;
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      .nbr_top {
        width: 100%;
        // background: linear-gradient(
        //   133deg,
        //   #ffa370 0%,
        //   #6d9eff 0%,
        //   #be8aff 100%
        // );
        border-radius: 20px;
        margin-bottom: 30px;
        position: relative;
        padding: 10px;
        padding-top: 40px;

        .fast_tle {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #232323;
        }

        .fast_box {
          width: 100%;
          height: 167px;
          border-radius: 20px 20px 20px 20px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .fast_item {
            width: 100%;
            // width: 146px;
            height: 167px;
            background: #ffffff;
            border: 1px solid #e6efff;
            box-shadow: 1px 0px 3px 0px rgba(246, 249, 255, 0.38),
              -1px 0px 3px 0px #f6f9ff;
            border-radius: 22px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            font-size: 14px;
            cursor: pointer;
            margin-right: 12px;

            .fast_icon {
              width: 66px;
              height: 62px;
              object-fit: contain;
            }
          }

          .fast-check {
            border: 3px solid #ff8400;
          }
        }
      }

      // 第四个盒子
      .nblb_right1 {
        width: 100%;
        margin-bottom: 20px;

        // background: #fff;
        // border-radius: 20px;
        // box-sizing: border-box;
        // padding: 10px;
        & ::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 5px;
          /*高宽分别对应横竖滚动条的尺寸*/
          height: 5px;
        }

        & ::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 3px #ff80ae;
          background: #ff80ae;
        }

        & ::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          background: #ffffff;
        }

        .nblbr_top {
          width: 100%;
          height: 60px;
          background: url("../../assets/oa/new/right1-top.png") no-repeat;
          background-size: 100% 100%;
          text-align: center;
          line-height: 60px;

          .nblbrt_left {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
          }
        }

        .nblbrt_right {
          width: 100%;
          background-color: #fff;

          .com_nav {
            width: 100%;
            color: #ff6c00;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            // justify-content: space-around;
            background-color: #fff;
            box-sizing: border-box;
            // height: 96px;
            padding: 18px 15px;
            font-weight: 400;
            overflow-x: auto;

            .cn_item {
              width: 120px;
              height: 44px;
              text-align: center;
              line-height: 44px;
              cursor: pointer;
              transition: linear 0.2s all;
              border: 1px solid #ff6c00;
              border-radius: 8px;
              position: relative;
              margin-right: 10px;
              white-space: nowrap;

              .redFlag {
                position: absolute;
                top: -3px;
                right: 0;
                width: 20px;
                height: 20px;
                line-height: 20px;
                background: red;
                color: #fff;
                border-radius: 50%;
                font-size: 12px;
                text-align: center;
              }
            }

            .active {
              color: #fff;
              // padding: 6px 8px;
              font-size: 17px;
              background: linear-gradient(90deg, #ff3000 0%, #ff9600 94%);
              border-radius: 8px;
            }
          }
        }

        .nblbr_bottom {
          height: 216px;
          overflow-y: auto;
          text-align: center;
          background-color: #fff;
          border-radius: 0 0 20px 20px;

          .com_more {
            text-align: right;
            padding-right: 20px;
            width: 100%;
            cursor: pointer;
          }

          .com_list {
            color: #585858;

            .comi_item {
              cursor: pointer;
              display: flex;
              padding: 10px 20px;
              justify-content: space-between;
              align-items: center;
              font-size: 15px;

              .comii_point {
                width: 8px;
                height: 8px;
                background: #4484ff;
                border-radius: 50%;
                opacity: 1;
                margin-right: 10px;
              }

              .comii_right {
                color: #ff83b0;
                border: 1px solid #ff83b0;
                border-radius: 20px;
                cursor: pointer;
                transition: linear 0.2s all;
                width: 90px;
                height: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 400;
              }

              .comii_left {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 500px;
                flex: 1;
              }

              .comii_right:hover {
                color: #fff;
                transform: scale(1.1);
                background: linear-gradient(270deg, #ff83b0 0%, #ff488b 100%);
                border-radius: 20px;
              }
            }

            .more_btn {
              color: #ff83b0;
              border: 1px solid #ff83b0;
              width: 110px;
              padding: 3px;
              text-align: center;
              border-radius: 20px;
              margin: 0 auto;
              cursor: pointer;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      .nbr_bottom {
        width: 100%;
        height: 335px;
        // flex: 6;
        background: #fff;
        border-radius: 20px;
        position: relative;
        box-sizing: border-box;
        padding: 20px;

        & ::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 5px;
          /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }

        & ::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 3px #ff6f00;
          background: #ff6f00;
        }

        & ::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          background: #ffffff;
        }

        .nbr_bottom-h {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;

          .nbrb_tle {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #232323;
            display: flex;
            align-items: center;

            img {
              width: 41px;
              height: 38px;
              object-fit: contain;
              margin-right: 12px;
            }
          }

          .nbrb_r {
            display: flex;
            align-items: center;

            .add_icon {
              cursor: pointer;
              width: 28px;
              height: 28px;
              margin-left: 8px;
            }

            .tday {
              color: #ff7200;
              cursor: pointer;
              width: 50px;
              height: 28px;
              line-height: 28px;
              background: #ffead3;
              border-radius: 4px;
              text-align: center;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .bottom_tle {
    color: #149cfb;
    background: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    box-sizing: border-box;
  }
}

.oa_main {
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-family: Source Han Sans SC-Regular, Source Han Sans SC;
  overflow: hidden;
  // overflow: hidden;
  box-sizing: border-box;
  background: linear-gradient(45deg, #c1d6f3 0%, #ded5fa 100%);
  padding: 10px;

  .noa_box {
    width: 100%;
    height: 100%;
    // min-width: 1200px;
    max-width: 1600px;
    min-height: 600px;
    max-height: 900px;
    margin: 0 auto;
    display: flex;
    -ms-overflow-style: none; /* 针对IE和Edge */
    scrollbar-width: none; /* 针对Firefox */

    /* Webkit浏览器（例如Chrome和Safari）需要一些额外的CSS来隐藏滚动条 */
    ::-webkit-scrollbar {
      display: none;
    }

    /* 针对WebKit浏览器（例如Chrome和Safari），还需要使用以下属性来隐藏滚动条 */
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .nob_left {
      flex: 2;
      background: #fff;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 15px;

      .nobl_tle {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        .nobll_img {
        }

        .nobll_msg {
        }
      }

      .nobl_user {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10%;
        overflow: hidden;
        min-width: 260px;

        .user_hello {
          font-size: 17px;
        }

        .ui_box {
          position: relative;
          margin-bottom: 18px;
          margin-top: 60px;
          width: 30%;

          .user_sex {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }

        .user_img {
          max-width: 120px;
          width: 100%;

          transition: 0.3s linear all;
          border-radius: 50%;
          box-shadow: -1px 2px 16px 0 hsla(259, 35%, 68%, 0.39);
        }

        .user_info {
          width: 220px;
          height: 30px;
          background: #dbe4ff;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #558aed;
          font-size: 16px;
          margin: 17px auto 22px;
        }

        .user_famous {
          width: 147px;
          height: 40px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 24px;
          text-align: center;
          margin-top: 16px;
        }

        .fast_entrance {
          padding-top: 20px;

          .fast_entrance_title {
            padding-left: 20px;

            .fast_entrance_title_item {
              width: 136px;
              border-radius: 40px;
              color: #ffffff;
              text-align: center;
              background: linear-gradient(90deg, #8d6eff 0%, #6d92fe 100%);
            }
          }

          .fast_entrance_box {
            margin-top: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            overflow-y: auto;
            padding: 12px 0 38px;

            .fe_item {
              width: 29%;
              text-align: center;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #212121;
              // margin-top: 10px;
              margin-left: 10px;
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              // margin-top: 10px;
              transition: 0.2s ease-in-out all;
              box-sizing: border-box;
              padding: 10px 0;

              img {
                width: 45px;
              }
            }

            .fe_item:hover {
              box-shadow: -1px 2px 16px 0 hsla(0, 0%, 42%, 0.39);
              transform: translateY(-10px);
              border-radius: 15px;
            }
          }
        }
      }
    }

    .nob_right {
      flex: 8;
      display: flex;
      flex-direction: column;

      .nr_top {
        height: 70px;
        background: linear-gradient(90deg, #a48cff 19%, #719cff 87%);
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .nrt_msg {
          color: #fff;
          flex: 1;
          text-align: center;
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: bold;
        }

        .nrt_act {
          display: flex;
          align-items: center;
          width: 400px;
          justify-content: flex-end;
          padding-right: 30px;

          img {
            width: 30px;
            height: 30px;
          }

          .user_img2 {
            width: 40px;
            height: 40px;
            margin: 0 20px;
            border-radius: 50%;
          }
        }
      }

      .nr_bottom {
        display: flex;
        flex: 1;

        .nrb_left {
          cursor: pointer;
          flex: 1;
          display: flex;
          flex-direction: column;

          .nrbl_top {
            flex: 3;
            background: #fff;
            border-radius: 10px;
            padding: 10px 30px;
            max-height: 340px;
            display: flex;

            .nrblt_left {
              transition: all 0.15s linear;
              flex: 3;
              margin-right: 20px;
              position: relative;

              .pop_tle {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 40px;
                background: #00000048;
                text-align: center;
                line-height: 40px;
                color: #fff;
                white-space: nowrap;
                /* 不换行 */
                overflow: hidden;
                /* 隐藏溢出的文本 */
                text-overflow: ellipsis;
                /* 使用省略号表示溢出的文本 */
              }

              img {
                width: 100%;
                height: 100%;
                // max-width: 500px;
                object-fit: cover;
              }
            }

            .nrblt_left:hover {
              box-shadow: -1px 2px 16px 0 hsla(0, 0%, 42%, 0.39);
              transform: translateY(-10px);
            }

            .nrblt_right {
              flex: 1;

              .nr_img {
                position: relative;
                max-height: 135px;
                max-width: 240px;
                cursor: pointer;
                transition: all 0.15s linear;

                .pop_tle {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 40px;
                  background: #00000048;
                  text-align: center;
                  line-height: 40px;
                  color: #fff;
                  white-space: nowrap;
                  /* 不换行 */
                  overflow: hidden;
                  /* 隐藏溢出的文本 */
                  text-overflow: ellipsis;
                  /* 使用省略号表示溢出的文本 */
                }
              }

              .nr_img:nth-child(2) {
                margin-top: 10px;
              }

              .nr_img:hover {
                box-shadow: -1px 2px 16px 0 hsla(0, 0%, 42%, 0.39);
                transform: translateY(-10px);
              }

              img {
                max-height: 135px;
                max-width: 240px;
              }
            }

            .nrbr_bottom {
              flex: 2;
              background: #fff;
              border-radius: 10px;
              margin-top: 10px;
              // padding: 15px 20px;
              max-height: 300px;
              overflow: hidden;

              .nrbrb_tle {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 8px;
                border-bottom: 1px solid #f3f4ff;
                margin-bottom: 14px;
                font-size: 20px;
              }

              .nrbrb_box {
                .nrb_top_box {
                  display: flex;
                  justify-content: space-between;
                  cursor: pointer;

                  img {
                    width: 280px;
                    height: 240px;
                    object-fit: cover;
                    margin-right: 10px;
                  }

                  .nrbtb_msg {
                    flex: 1;

                    .nrbrb_con_top {
                      // display: flex;
                      // align-items: center;
                      // justify-content: space-between;
                      .spans {
                        white-space: nowrap; /* 禁止换行 */
                        display: inline-block;
                        width: 400px;
                        /* 不换行 */
                        overflow: hidden;
                        /* 隐藏溢出的文本 */
                        text-overflow: ellipsis;
                        /* 使用省略号表示溢出的文本 */
                        font-weight: 500;
                        font-size: 20px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        color: #191919;
                      }
                    }

                    .nrbrb_con_top:hover {
                      .spans {
                        color: rgb(245, 12, 12);
                      }
                    }

                    div:nth-child(1) {
                      font-size: 20px;
                      font-family: PingFang SC;
                      font-weight: bold;
                      color: #191919;
                    }

                    div:nth-child(2) {
                      font-family: PingFang SC;
                      font-weight: 500;
                      color: #999999;
                      margin-top: 8px;
                      max-height: 172px;
                      overflow: auto;
                    }

                    div:nth-child(3) {
                      font-size: 14px;
                      font-family: PingFang SC;
                      font-weight: 500;
                      color: #999999;
                      margin-top: 13px;
                    }
                  }
                }

                // .nrb_top_box:hover {
                //   div {
                //     font-size: 20px;
                //     font-family: PingFang SC;
                //     font-weight: bold;
                //     color: rgb(245, 12, 12);
                //   }
                // }

                .nrbrb_con1 {
                  div {
                    margin-top: 10px;
                    cursor: pointer;
                    white-space: nowrap;
                    /* 不换行 */
                    overflow: hidden;
                    /* 隐藏溢出的文本 */
                    text-overflow: ellipsis;
                    /* 使用省略号表示溢出的文本 */
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  }

                  .spans {
                    display: inline-block;
                    width: 250px;
                    /* 不换行 */
                    overflow: hidden;
                    /* 隐藏溢出的文本 */
                    text-overflow: ellipsis;
                    /* 使用省略号表示溢出的文本 */
                    font-size: 13px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #000000;
                  }

                  div:hover {
                    .spans {
                      color: rgb(245, 12, 12);
                    }
                  }
                }
              }
            }
          }

          .nrbl_bottom {
            flex: 4;
            background: #fff;
            border-radius: 10px;
            margin-top: 10px;
            padding: 10px;

            .nrbl_nav {
              display: flex;
              align-items: center;
              justify-content: space-around;
              border-bottom: 1px solid #f3f4ff;
              padding-bottom: 5px;
              position: relative;

              .nn_item {
                flex: 1;
                max-width: 180px;
                text-align: center;
                height: 40px;
                line-height: 40px;
                position: relative;
                cursor: pointer;
                user-select: none;
                font-size: 17px;

                .flag {
                  width: 40px;
                  height: 20px;
                  background: linear-gradient(90deg, #ff1800 17%, #ff00d2 83%);
                  border-radius: 10px 10px 10px 0px;
                  line-height: 20px;
                  position: absolute;
                  right: 0;
                  top: -5px;
                  color: #fff;
                }
              }

              .active {
                background: linear-gradient(90deg, #8d6eff 0%, #6d92fe 100%);
                border-radius: 20px;
                color: #fff;
                z-index: 999;
                transition: color 0.3s ease-in-out;
              }

              .slider {
                position: absolute;
                bottom: 10%;
                height: 3px;
                background: linear-gradient(90deg, #8d6eff 0%, #6d92fe 100%);
                border-radius: 20px;
                transition: left 0.3s ease-in-out;
                height: 40px;
                width: 20%;
                max-width: 180px;
                z-index: 1;
              }
            }

            .nrbl_box {
              max-height: 390px;
              overflow: auto;

              &::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 5px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
              }

              &::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 3px #70a1ff;
                background: #70a1ff;
              }

              &::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background: #ffffff;
              }

              &::-moz-scrollbar {
                /*滚动条整体样式*/
                width: 5px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
              }

              &::-moz-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                -moz-box-shadow: inset 0 0 3px #70a1ff;
                background: #70a1ff;
              }

              &::-moz-scrollbar-track {
                /*滚动条里面轨道*/
                -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background: #ffffff;
              }

              .nrblb_item {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 10px 72px 10px 25px;
                margin-top: 13px;
                transition: 0.2s linear all;

                .examine {
                  flex: 1;
                  margin: 0 10px;
                  min-width: 307px;

                  .tips {
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #999999;
                  }

                  .over_time {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #ff0000;
                  }

                  .normal {
                    background: #4d7aff;
                    border-radius: 6px 6px 6px 0px;
                    color: #fff;
                    width: 30px;
                    height: 16px;
                    line-height: 16px;
                    font-size: 12px;
                    display: inline-block;
                    text-align: center;
                  }

                  .normalIcon {
                    background: red;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    display: inline-block;
                  }
                }

                .approval {
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #999999;
                  min-width: 90px;
                }

                .ni_img {
                  margin-right: 10px;
                  width: 40px;
                  height: 40px;
                }

                &:hover {
                  background: #eef3ff;
                  transform: translateY(-10px);

                  .approval {
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #ff0000;
                  }
                }
              }
            }
          }
        }

        .nrb_right {
          width: 410px;
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          .nrbr_top {
            // flex: 6;
            background: #fff;
            border-radius: 10px;

            .nbr_calendar {
              overflow: auto;
              width: 100%;
              height: 100%;
              background: #fff;
              border-radius: 4px;
              box-sizing: border-box;
              padding: 5px 10px;
              position: relative;
              border-radius: 10px;

              &::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 5px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
                border-radius: 10px;
              }

              &::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 3px #70a1ff;
                background: #70a1ff;
              }

              &::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background: #ffffff;
              }

              &::-moz-scrollbar {
                /*滚动条整体样式*/
                width: 5px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
              }

              &::-moz-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                -moz-box-shadow: inset 0 0 3px #70a1ff;
                background: #70a1ff;
              }

              &::-moz-scrollbar-track {
                /*滚动条里面轨道*/
                -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                background: #ffffff;
              }

              .add_icon {
                position: absolute;
                top: 27px;
                right: 25px;
                cursor: pointer;
                width: 28px;
              }

              .calendar_top {
                display: flex;
                align-items: center;
                margin: 20px 0;
                padding: 0 20px;

                .ct_day {
                  width: 60px;
                  height: 70px;
                  font-size: 22px;
                  font-family: PingFang SC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #232323;
                  margin: 5px 10px 5px 20px;
                  text-align: center;
                  line-height: 70px;
                }

                .ct_years {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  vertical-align: middle;
                  font-size: 22px;
                  font-family: PingFang SC-Semibold, PingFang SC;
                  color: #232323;
                  width: 135px;

                  .left_triangle {
                    transform: scale(-1, 1);
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                  }

                  .right_triangle {
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    margin-left: 14px;
                  }
                }
              }

              .calendar_center {
                user-select: none;

                .cct_list {
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  font-family: PingFang SC-Semibold, PingFang SC;

                  .cct_item {
                    width: 50px;
                    font-size: bold;
                    color: #232323;
                  }

                  text-align: center;

                  div {
                    width: 50px;
                  }
                }

                .ccb_list {
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  margin-top: 5px;
                  font-family: PingFang SC-Semibold, PingFang SC;
                  flex-wrap: wrap;

                  .ccb_item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 34px;
                    height: 34px;
                    cursor: pointer;
                    margin: 2px 10px;

                    .dot {
                      width: 4px;
                      height: 4px;
                      background: #ff0000;
                      border-radius: 50%;
                    }

                    .new_c {
                      color: #232323;
                    }

                    .lunar_c {
                      margin: 2px 0 1px;
                      font-size: 10px;
                    }
                  }

                  .active {
                    border-radius: 50%;
                    background: #ffe7e1;
                    color: #ff6f00;
                    padding-bottom: 2px;
                    background: linear-gradient(0deg, #7d7aff 19%, #80a7ff 87%);

                    .new_c {
                      color: #fff;
                    }

                    .dot {
                      width: 4px;
                      height: 4px;
                      background: #fff;
                      border-radius: 50%;
                    }
                  }

                  .no_belong {
                    color: #d3dbe7;
                    cursor: default;
                  }
                }
              }

              .calendar_bottom {
                // height: 122px;
                overflow: auto;
                // margin-top: 15px;
                /* padding-top: 20px; */
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding-top: 5px;
                font-family: PingFang SC-Semibold, PingFang SC;
                height: 108px;
                overflow: auto;
                background: #f4f7ff;
                border-radius: 10px;
                line-height: 108px;
                padding-top: 15px;

                .clb_item {
                  margin-bottom: 14px;
                  width: 100%;
                  height: 60px;
                  background: #eaeef8;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;

                  .clbi_left {
                    font-size: 18px;
                    padding: 3px 20px;
                  }

                  .clbi_right {
                  }
                }

                &::-webkit-scrollbar {
                  /*滚动条整体样式*/
                  width: 5px;
                  /*高宽分别对应横竖滚动条的尺寸*/
                  height: 1px;
                }

                &::-webkit-scrollbar-thumb {
                  /*滚动条里面小方块*/
                  border-radius: 10px;
                  -webkit-box-shadow: inset 0 0 3px #70a1ff;
                  background: #70a1ff;
                }

                &::-webkit-scrollbar-track {
                  /*滚动条里面轨道*/
                  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                  border-radius: 10px;
                  background: #ffffff;
                }

                &::-moz-scrollbar {
                  /*滚动条整体样式*/
                  width: 5px;
                  /*高宽分别对应横竖滚动条的尺寸*/
                  height: 1px;
                }

                &::-moz-scrollbar-thumb {
                  /*滚动条里面小方块*/
                  border-radius: 10px;
                  -moz-box-shadow: inset 0 0 3px #70a1ff;
                  background: #70a1ff;
                }

                &::-moz-scrollbar-track {
                  /*滚动条里面轨道*/
                  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
                  border-radius: 10px;
                  background: #ffffff;
                }
              }
            }
          }

          .nrbr_bottom {
            flex: 1;
            background: #fff;
            border-radius: 10px;
            margin-top: 10px;
            padding: 15px 20px;
            // max-height: 260px;

            .nrbrb_tle {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 8px;
              border-bottom: 1px solid #f3f4ff;
              margin-bottom: 14px;
            }

            .nrbrb_box {
              .nrb_top_box {
                display: flex;
                justify-content: space-between;
                cursor: pointer;

                img {
                  width: 280px;
                  height: 124px;
                  object-fit: cover;
                  margin-right: 10px;
                }

                .nrbtb_msg {
                  flex: 1;

                  div:nth-child(1) {
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #191919;
                  }

                  div:nth-child(2) {
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #999999;
                    margin-top: 13px;
                  }

                  div:nth-child(3) {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #999999;
                    margin-top: 13px;
                  }
                }
              }

              .nrb_top_box:hover {
                div:nth-child(1) {
                  font-size: 20px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: rgb(245, 12, 12);
                }
              }

              .nrbrb_con {
                div {
                  margin-top: 5px;
                  cursor: pointer;
                  white-space: nowrap;
                  /* 不换行 */
                  overflow: hidden;
                  /* 隐藏溢出的文本 */
                  text-overflow: ellipsis;
                  /* 使用省略号表示溢出的文本 */
                }

                div:hover {
                  color: rgb(245, 12, 12);
                }

                span {
                  font-weight: 500;
                }
              }
            }

            .fast_entrance {
              .fast_entrance_title {
                padding-left: 20px;

                .fast_entrance_title_item {
                  width: 136px;
                  border-radius: 40px;
                  color: #ffffff;
                  text-align: center;
                  background: linear-gradient(90deg, #8d6eff 0%, #6d92fe 100%);
                }
              }

              .fast_entrance_box {
                margin-top: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                overflow-y: auto;
                padding: 10px 0;

                .fe_item {
                  width: 29%;
                  text-align: center;
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #212121;
                  margin-left: 10px;
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  cursor: pointer;
                  margin-top: 10px;
                  transition: 0.2s ease-in-out all;
                  box-sizing: border-box;
                  padding: 5px 0;

                  img {
                    width: 45px;
                  }
                }

                .fe_item:hover {
                  box-shadow: -1px 2px 16px 0 hsla(0, 0%, 42%, 0.39);
                  transform: translateY(-10px);
                  border-radius: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.no_data {
  width: 200px;
  margin: auto;
  display: block;
  margin-top: 70px;
}
</style>
<style lang="scss">
.nbltl_right {
  .el-loading-spinner .path {
    -webkit-animation: loading-dash 1.5s ease-in-out infinite;
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    /* stroke: var(--el-color-primary); */
    stroke-linecap: round;
    stroke: #ff7c32;
  }
}

.nbltr_right {
  .el-loading-spinner .path {
    -webkit-animation: loading-dash 1.5s ease-in-out infinite;
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    /* stroke: var(--el-color-primary); */
    stroke-linecap: round;
    stroke: #6d9fff;
  }
}

.nblbl_right {
  .el-loading-spinner .path {
    -webkit-animation: loading-dash 1.5s ease-in-out infinite;
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    /* stroke: var(--el-color-primary); */
    stroke-linecap: round;
    stroke: #7d2be3;
  }
}

.nblbr_bottom {
  .el-loading-spinner .path {
    -webkit-animation: loading-dash 1.5s ease-in-out infinite;
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    /* stroke: var(--el-color-primary); */
    stroke-linecap: round;
    stroke: #ff78a9;
  }
}

.calendar_bottom {
  .no_msg {
    color: #6997ff;
    text-align: center;
    width: 100%;
    // margin: 83px auto;
  }

  .el-timeline-item {
    padding-bottom: 19px;
  }

  .el-timeline-item__tail {
    border-left: 2px solid #bbd2ff;
  }

  .time_stamp {
    display: flex;
    align-items: center;
    color: #363737;
    height: 14px;

    .tle_leng {
      max-width: 300px;
      white-space: nowrap;
      /*强制单行显示*/
      text-overflow: ellipsis;
      /*超出部分省略号表示*/
      overflow: hidden;
      /*超出部分隐藏*/
      max-width: 260px;
      /*设置显示的最大宽度*/
      display: inline-block;
    }

    font-size: 14px;
  }

  .ts_box {
    color: #808080;
    margin-top: 10px;
    font-size: 14px;
  }

  .be_done {
    width: 36px;
    height: 16px;
    border-radius: 10px 10px 10px 0px;
    opacity: 1;
    background: #4484ff;
    color: #fff;
    font-size: 8px;
    margin-left: 15px;
    text-align: center;
    line-height: 16px;

    .shrinkFont {
      display: inline-block;
    }
  }

  .other {
    width: 48px;
    height: 16px;
    border-radius: 30px 30px 30px 30px;
    opacity: 1;
    border: 1px solid #53c8ae;
    color: #53c8ae;
    font-size: 8px;
    margin-left: 15px;
    text-align: center;

    .shrinkFont {
      display: inline-block;
      transform: translateY(-1px);
    }
  }

  .close {
    display: inline-block;
    margin-left: 10px;
    width: 16px;
    cursor: pointer;
  }

  .vacation {
    width: 36px;
    height: 16px;
    border-radius: 10px 10px 10px 0px;
    opacity: 1;
    background: #ff6f00;
    color: #fff;
    font-size: 8px;
    margin-left: 15px;
    padding: 0 6px;
    text-align: center;
    line-height: 16px;
  }
}

.calendar_center {
  .el-carousel__arrow {
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    height: 36px;
    width: 36px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 50%;
    background-color: rgba(31, 45, 61, 0);
    color: #4484ff;
    position: absolute;
    top: 50%;
    z-index: 10;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%) scale(1.5);
    text-align: center;
    font-size: 20px;
    font-weight: 900;
  }

  .el-carousel__arrow:hover {
    background-color: rgba(31, 45, 61, 0);
  }

  .el-carousel__arrow--right {
    right: -5px;
  }

  .el-carousel__arrow--left {
    left: -5px;
  }
}

.el-carousel__item.is-animating {
  transition: opacity 1s ease-in-out !important;
}

.el-carousel__arrow i {
  color: #ff6f00;
}

// @media screen and (max-height: 800px) {
//   .fe_item img {
//     width: 30px !important;
//   }

//   .fast_entrance {
//     margin-top: 20px !important;
//   }

//   // .oa_main .user_img {
//   //   width: 20% !important;
//   // }
//   .oa_main .noa_box .nob_right .nr_bottom .nrb_right .nrbr_top {
//     max-height: 400px;
//   }

//   .el-carousel__container {
//     height: 208px !important;
//   }

//   .oa_main
//     .noa_box
//     .nob_right
//     .nr_bottom
//     .nrb_right
//     .nrbr_top
//     .nbr_calendar
//     .calendar_center
//     .ccb_list
//     .ccb_item {
//     width: 25px !important;
//     height: 25px !important;
//     margin: 2px 14px !important;
//     font-size: 13px;
//   }

//   .oa_main
//     .noa_box
//     .nob_right
//     .nr_bottom
//     .nrb_right
//     .nrbr_top
//     .nbr_calendar
//     .calendar_bottom {
//     height: 111px !important;
//   }

//   .calendar_bottom .no_msg {
//     margin: 33px auto;
//   }

//   .oa_main .noa_box .nob_left .nobl_user {
//     margin-bottom: 0 !important;
//     padding-bottom: 50px;
//   }

//   .oa_main .noa_box .nob_left .nobl_user .user_famous {
//     font-size: 14px !important;
//     margin-top: 7px !important;
//   }

//   .oa_main .noa_box .nob_left .nobl_user .user_info {
//     font-size: 14px !important;
//     margin: 8px auto 12px !important;
//   }

//   .oa_main .noa_box .nob_right .nr_bottom .nrb_left .nrbl_bottom .nrbl_box {
//     max-height: 200px !important;
//   }

//   .no_data {
//     width: 200px;
//     margin: auto;
//     display: block;
//     margin-top: 0px !important;
//   }
// }

// @media screen and (max-height: 700px) {
//   .oa_main .noa_box .nob_right .nr_bottom .nrb_left .nrbl_top .nrblt_right img {
//     // max-height: 113px !important;
//   }

//   .oa_main
//     .noa_box
//     .nob_right
//     .nr_bottom
//     .nrb_left
//     .nrbl_bottom
//     .nrbl_box
//     .nrblb_item
//     .examine {
//     min-width: 238px !important;
//     font-size: 14px;
//   }

//   .oa_main
//     .noa_box
//     .nob_right
//     .nr_bottom
//     .nrb_left
//     .nrbl_bottom
//     .nrbl_box
//     .nrblb_item
//     .examine
//     .tips {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     width: 200px;
//   }

//   .oa_main .noa_box .nob_right .nr_bottom .nrb_right .nrbr_top {
//     max-height: 320px;
//   }

//   .oa_main .noa_box .nob_left .nobl_user .fast_entrance .fe_item {
//     width: 21% !important;
//     font-size: 12px !important;
//   }

//   .oa_main .noa_box .nob_right .nr_bottom .nrb_left .nrbl_top {
//     padding: 15px !important;
//   }

//   .oa_main .noa_box .nob_right .nr_top .nrt_msg {
//     font-size: 22px !important;
//   }
// }
</style>


